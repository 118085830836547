import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import * as moment from 'moment';
import { VaisFormModalComponent } from '../vais-form-modal/vais-form-modal.component';
import { Account, Address, Email } from 'src/app/enrollment-forms/models/account.model';
import { Provider } from 'src/app/enrollment-forms/models/provider.model';
import { VaisFormsService } from '../../services';
import { FormCanDeactivate } from 'src/app/authguard/form-can-deactivate';
import { HttpErrorService } from 'src/app/error-handler/http-error.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Phone } from 'src/app/enrollment-forms/models/phone';
import { FormsService } from 'src/app/enrollment-forms/services';

@Component({
  selector: 'app-vais-review-order',
  templateUrl: './vais-review-order.component.html',
  styleUrls: ['./vais-review-order.component.css']
})
export class VaisReviewOrderComponent extends FormCanDeactivate implements OnInit, OnDestroy {
  @Output() nextEvent = new EventEmitter<number>();
  @Output() previousEvent = new EventEmitter<number>();

  @ViewChild('formFive', { static: false }) form: NgForm;

  reviewForm: FormGroup;
  acceptTermsCheckbox = false;

  showPersonal: boolean;
  showCaregiver: boolean;
  showProduct: boolean;

  awayServiceDisabled = false;
  isCaregiverFormValid$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  isSubmitting: boolean;
  customerServicePhone: string;
  errorCreatingAccount: boolean;

  memberCardNo: string;
  memberName: string;

  caregiverFormValues$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  caregiverFormValues: any;
  memberFormValues: { [key: string]: AbstractControl }[];
  shipFormValues: any;
  isShipForm: boolean;

  prodTitle: string;
  prodDesc: string;
  prodImage: string;
  prodBundle: string;
  prodNo: string;
  prodMonthlyRate: number;

  isOtgPlusSelected = false;
  isOtgAwayServiceSelected = false;
  primaryPhoneType: string;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private reviewFormBuilder: FormBuilder,
    private formModalService: NgbModal,
    private vaisFormsService: VaisFormsService,
    private formsService: FormsService,
    private reviewRoute: Router,
    private httpErrorService: HttpErrorService
  ) {
    super();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit() {
    this.reviewForm = this.reviewFormBuilder.group({
      acceptTermsCheckbox: ['', Validators.required]
    });

    this.vaisFormsService.formMemberVerification$.subscribe(memberVerificationForm => {
      if (memberVerificationForm && memberVerificationForm.value && Object.keys(memberVerificationForm.value).length > 0) {
        this.upperCaseFormControl(memberVerificationForm.value.lastName);
        this.upperCaseFormControl(memberVerificationForm.value.firstName);
        this.upperCaseFormControl(memberVerificationForm.value.couponCode);

        this.memberCardNo = memberVerificationForm.value.couponCode;
      }
    });

    this.vaisFormsService.formMemberInfo$.subscribe(memberInformationForm => {
      if (memberInformationForm && memberInformationForm.controls && Object.keys(memberInformationForm.controls).length > 0) {
        this.memberFormValues = Array.of(memberInformationForm.controls);
        const memberInformation = this.memberFormValues[0];
        if (memberInformation) {
          this.upperCaseFormControl(memberInformation.firstName);
          this.upperCaseFormControl(memberInformation.middleName);
          this.upperCaseFormControl(memberInformation.lastName);
          this.upperCaseFormControl(memberInformation.address1);
          this.upperCaseFormControl(memberInformation.address2);
          this.upperCaseFormControl(memberInformation.city);
          this.upperCaseFormControl(memberInformation.zipCode);
          this.upperCaseFormControl(memberInformation.email);
          this.upperCaseFormControl(memberInformation.emailMatch);

          this.memberName = (memberInformation.firstName ? memberInformation.firstName.value + ' ' : '') +
            (memberInformation.middleName ? memberInformation.middleName.value + ' ' : '') +
            (memberInformation.lastName ? memberInformation.lastName.value : '');
        }
      }
    });

    this.vaisFormsService.formCaregiverInfo$.subscribe(caregiverForm => {
      if (caregiverForm && caregiverForm.controls && Object.keys(caregiverForm.controls).length > 0) {
        const caregiverForms = caregiverForm.controls.details as FormArray;

        caregiverForms.controls.forEach((individualCaregiver: FormGroup) => {
          this.upperCaseFormControl(individualCaregiver.controls.firstName);
          this.upperCaseFormControl(individualCaregiver.controls.middleName);
          this.upperCaseFormControl(individualCaregiver.controls.lastName);
          this.upperCaseFormControl(individualCaregiver.controls.email);
          this.upperCaseFormControl(individualCaregiver.controls.emailMatch);
        });

        this.caregiverFormValues$.next(caregiverForm.controls.details.value);
      }
    });

    this.vaisFormsService.formProductInfo$.subscribe(productForm => {
      if (productForm && productForm.controls && Object.keys(productForm.controls).length > 0) {
        this.isOtgPlusSelected = productForm.controls.otgPlusSelected.value;
        this.isOtgAwayServiceSelected = productForm.controls.otgAwayServiceSelected.value;

        if (productForm.controls.phoneType.value === this.vaisFormsService.phoneTypes.landline) {
          this.primaryPhoneType = 'Home';
        } else {
          this.primaryPhoneType = 'Cell';
        }
      }
    });

    this.getProductInformation();

    this.formsService.careDetails$.pipe(takeUntil(this.destroy$)).subscribe((careDetails: Provider) => {
      if (careDetails) {
        this.customerServicePhone = careDetails.phoneno + ' ' + careDetails.ext;
        this.awayServiceDisabled = careDetails.disableAwayService;
      }
    });

    this.vaisFormsService.isCaregiverFormValid$
      .pipe(takeUntil(this.destroy$))
      .subscribe(isCaregiverFormValid => {
        this.isCaregiverFormValid$.next(isCaregiverFormValid);
      });

    this.isSubmitting = false;
    this.errorCreatingAccount = false;
  }

  showForms(index: number) {
    this.vaisFormsService.viewAsModal$.next(true);
    const formModal = this.formModalService.open(VaisFormModalComponent, {
      windowClass: 'modal',
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });
    formModal.componentInstance.formType = index;
  }

  submitOrder() {
    this.isSubmitting = true;
    this.caregiverFormValues = this.caregiverFormValues$.value;

    const accounts: Array<Account> = [];
    const memberAddresses: Address[] = [];
    const memberPhones: Phone[] = [];
    const memberEmails: Email[] = [];

    memberAddresses.push(this.formatServiceAddress(this.memberFormValues[0]));
    memberPhones.push({ phoneNumber: this.memberFormValues[0].phone.value, type: this.primaryPhoneType });
    memberEmails.push({ address: this.memberFormValues[0].email.value, type: 'Personal' });

    accounts.push(this.accountData(true,
      this.memberFormValues[0],
      memberPhones,
      memberEmails,
      memberAddresses,
      this.getValue(this.memberFormValues[0].dob),
      this.getValue(this.memberFormValues[0].gender)
    ));

    this.caregiverFormValues.forEach(caregiverControls => {
      accounts.push(this.accountData(false,
        caregiverControls,
        caregiverControls.phoneDetails.map((phone: any) => ({
          phoneNumber: phone.carePhone,
          type: phone.carePhoneType
        })),
        [{
          address: caregiverControls.email,
          type: 'Home'
        }],
        caregiverControls.address,
        '',
        ''
      ));
    });

    this.vaisFormsService.createAccount$(accounts, this.prodBundle).subscribe(() => {
      this.formsService.clearForms();
      this.vaisFormsService.clearForms();
      this.isSubmitting = false;
      this.errorCreatingAccount = false;

      const providerRoute = this.reviewRoute.url;
      this.reviewRoute.navigateByUrl(`${providerRoute}/success`);
    }, (error: unknown) => {
      this.formsService.clearForms();
      this.vaisFormsService.clearForms();
      this.isSubmitting = false;
      this.errorCreatingAccount = true;

      if (error instanceof HttpErrorResponse) {
        this.httpErrorService.logHttpErrorDetailsIfDevelopment(error);
      }
    });
  }

  exitPage() {
    const providerRoute = this.reviewRoute.url.split('/')[1];
    this.formsService.clearForms();
    this.vaisFormsService.clearForms();
    this.reviewRoute.navigateByUrl(`${providerRoute}`);
  }

  private getProductInformation() {
    this.vaisFormsService.productInfo$.subscribe(product => {
      if (product) {
        this.prodTitle = product.prodTitle;
        this.prodDesc = product.prodDescription;
        this.prodImage = product.prodImage;
        this.prodBundle = product.bundleNo;
        this.prodNo = product.prodNo;
        this.prodMonthlyRate = product.prodMonthlyRate;
      }
    });
  }

  private accountData(
    isCareRecipient: boolean,
    formControls: any,
    phoneData: Array<Phone>,
    emails?: Array<Email>,
    addressData?: Array<Address>,
    dob?: string,
    gender?: string
  ): Account {
    return {
      requiresTTY: false,
      firstName: (isCareRecipient ? formControls.firstName.value : formControls.firstName).toUpperCase(),
      middleName: (isCareRecipient ? formControls.middleName.value : formControls.middleName).toUpperCase(),
      lastName: (isCareRecipient ? formControls.lastName.value : formControls.lastName).toUpperCase(),
      language: (isCareRecipient ? formControls.language.value : formControls.language),
      gender,
      phones: phoneData,
      addresses: isCareRecipient ? addressData : [],
      emails: emails ? emails.map(email => ({
        address: email.address.toUpperCase(),
        type: email.type
      })) : null,
      dateOfBirth: isCareRecipient ? moment(dob).format('YYYY-MM-DD') : null,
      isCareRecipient,
      locationOfHouseKey: (isCareRecipient && formControls.houseKeyExists.value) ? formControls.locationOfHouseKey.value : '',
      jobNumbers: null,
      relationship: isCareRecipient ? null : formControls.relationship,
      isResponder: isCareRecipient ? null : formControls.role.includes(this.vaisFormsService.contactTypes.responder),
      isAwayContact: isCareRecipient ? null : formControls.role.includes(this.vaisFormsService.contactTypes.awayServiceContact),
      isNotifier: isCareRecipient ? null : formControls.role.includes(this.vaisFormsService.contactTypes.notify)
    };
  }

  private formatServiceAddress(address: any): Address {
    return this.formatAddress(
      address.address1.value,
      address.address2.value,
      address.city.value,
      address.zipCode.value,
      'Home',
      address.state.value);
  }

  private formatAddress(line1: string, line2: string, city: string,
    zip: string, addressType: string, state: string): Address {
    return {
      line1: line1.toUpperCase(),
      line2: line2.toUpperCase(),
      city: city.toUpperCase(),
      zip,
      country: 'USA',
      addressType,
      state,
      status: 'Active',
      isAddressValidated: true,
    };
  }

  private getValue(formControl) {
    if (!formControl) {
      return null;
    }
    return formControl.value;
  }

  private upperCaseFormControl(control: AbstractControl) {
    if (control && control.value) {
      control.setValue(control.value.toUpperCase());
    }
  }
}
