import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

import { AppConfig } from 'src/app/shared/services/app-config.service';
import { AuthenticatePayload } from 'src/app/config-manager/model/authenticate-payload.model';
import { LoggedInUserData } from '../models/logged-in-user-data.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private isLoggedIn = false;
  private isLoggedInSubject$: BehaviorSubject<boolean>;

  constructor(
    private httpClient: HttpClient,
    private appConfig: AppConfig,
    private router: Router
  ) {
    this.isLoggedIn = this.isLoginDataStored() ? true : false;
    this.isLoggedInSubject$ = new BehaviorSubject<boolean>(this.isLoggedIn);

    if (this.isLoggedIn && this.loginTimedOut()) {
      this.logout();
    }
  }

  authenticate$(credential: AuthenticatePayload): Observable<any> {
    const authenticateApiOptions = this.enrollmentApiOptions();
    return this.httpClient.post(this.appConfig.config.enrollmentApiOktaAuth, credential, authenticateApiOptions);
  }

  updateLoggedInStatus(isLoggedIn: boolean) {
    if (isLoggedIn === false) {
      localStorage.removeItem(this.appConfig.config.localStorageRepUser);
    }

    this.isLoggedIn = isLoggedIn;
    this.isLoggedInSubject$.next(isLoggedIn);
  }

  logout() {
    localStorage.clear();
    this.updateLoggedInStatus(false);
    this.httpClient.get(this.appConfig.config.enrollmentApiOktaLogout).subscribe(
      () => { }, () => { }, () => this.redirectToLogin()
    );
  }

  redirectToLogin() {
    this.router.navigateByUrl(`/configmanager-login`);
  }

  isLoggedIn$(): Observable<boolean> {
    return this.isLoggedInSubject$.asObservable();
  }

  repUserFormatted(): string {
    const repUserJson = localStorage.getItem(this.appConfig.config.localStorageRepUser);

    if (this.isLoggedIn && repUserJson) {
      try {
        const repUser: LoggedInUserData = JSON.parse(repUserJson);
        return `${repUser.userName} (${repUser.firstName} ${repUser.lastName})`;
      } catch {
        return `Unknown (unknown)`;
      }
    } else if (this.isLoggedIn) {
      return `Unknown (unknown)`;
    } else {
      return 'Subscriber (self)';
    }
  }

  private isLoginDataStored(): boolean {
    const repDataString = localStorage.getItem(this.appConfig.config.localStorageRepUser);

    if (repDataString) {
      const repData: LoggedInUserData = JSON.parse(repDataString);
      if (repData && !!repData.userName) {
        return true;
      }
    }
    return false;
  }

  private loginTimedOut(): boolean {
    try {
      const lastActivityRaw = localStorage.getItem(this.appConfig.config.localStorageLastActivity);

      if (!lastActivityRaw) {
        return true;
      }

      const lastActivity = Number(lastActivityRaw);

      return !lastActivity ||
        Date.now() - lastActivity > this.appConfig.config.inactivityTimeoutInMinutes * 60000;
    } catch {
      return true;
    }
  }

  private enrollmentApiOptions() {
    return {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('appId', this.appConfig.config.enrollmentAppId)
        .set('appSecret', this.appConfig.config.enrollmentAppSecret)
    };
  }
}
