import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ConfigManagerComponent } from './config-manager/config-manager.component';
import { ConfigManagerLoginComponent } from './config-manager-login/config-manager-login.component';
import { DefaultMessageComponent } from './default-message/default-message.component';
import { StepperControlComponent, SuccessPageComponent } from './enrollment-forms/components';
import { EligibilitySearchComponent } from './eligibility-search/eligibility-search.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { AuthGuard } from './shared/auth.guard';
import { LoggedInAuthGuard } from './shared/logged-in-auth.guard';
import { ConversionStepperControlComponent } from
  './conversions/components/conversion-stepper-control/conversion-stepper-control.component';
import { VaisSuccessPageComponent } from './vais/components/vais-success-page/vais-success-page.component';
import { VaisStepperControlComponent } from './vais/components/vais-stepper-control/vais-stepper-control.component';
import { VaisLandingPageComponent } from './vais/components/vais-landing-page/vais-landing-page.component';

const routes: Routes = [
  {
    path: 'eligibility-search',
    children: [
      {
        path: '',
        component: EligibilitySearchComponent,
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'humana',
    children: [
      {
        path: '',
        component: LandingPageComponent
      },
      {
        path: 'enrollmentforms',
        component: StepperControlComponent,
      },
      {
        path: 'enrollmentforms/success',
        component: SuccessPageComponent
      },
    ]
  },
  {
    path: 'humanagr',
    children: [
      {
        path: '',
        component: LandingPageComponent
      },
      {
        path: '**',
        redirectTo: '/humanagr',
      },
    ]
  },
  {
    path: 'uhc',
    children: [
      {
        path: '',
        component: LandingPageComponent
      },
      {
        path: 'convert',
        children: [
          {
            path: '**',
            component: ConversionStepperControlComponent
          }
        ]
      },
      {
        path: 'enrollmentforms',
        component: StepperControlComponent
      },
      {
        path: 'enrollmentforms/success',
        component: SuccessPageComponent
      },
    ]
  },
  {
    path: 'uhcgr',
    children: [
      {
        path: '',
        component: LandingPageComponent
      },
      {
        path: 'enrollmentforms',
        component: StepperControlComponent
      },
      {
        path: 'enrollmentforms/success',
        component: SuccessPageComponent
      },
    ]
  },
  {
    path: 'vais',
    children: [
      {
        path: '',
        component: VaisLandingPageComponent
      },
      {
        path: 'uhc',
        children: [
          {
            path: '',
            component: VaisLandingPageComponent
          },
          {
            path: 'enrollmentforms',
            component: VaisStepperControlComponent
          },
          {
            path: 'enrollmentforms/success',
            component: VaisSuccessPageComponent
          },
        ]
      },
      {
        path: 'uhcgr',
        children: [
          {
            path: '',
            component: VaisLandingPageComponent
          },
          {
            path: 'enrollmentforms',
            component: VaisStepperControlComponent
          },
          {
            path: 'enrollmentforms/success',
            component: VaisSuccessPageComponent
          },
        ]
      },
    ]
  },
  {
    path: 'configmanager',
    children: [
      {
        path: '',
        component: ConfigManagerComponent
      }
    ]
  },
  {
    path: 'configmanager-login',
    children: [
      {
        path: '',
        component: ConfigManagerLoginComponent,
        canActivate: [LoggedInAuthGuard]
      }
    ]
  },
  {
    path: '',
    component: DefaultMessageComponent
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppRoutingModule { }
