import { Component, OnInit, ViewChild, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FormsService } from '../../services';
import { ModalComponent } from '../../../shared/components';
import { FormCanDeactivate } from 'src/app/authguard/form-can-deactivate';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { ProductModal } from 'src/app/shared/models/product-modal.model';
import { ProductReplacement } from '../../../shared/models/product-replacement.model';
import { matchField } from 'src/app/validators/match-field.validator';
import { Product } from '../../../shared/models/product.model';

@Component({
  selector: 'app-product-selection',
  templateUrl: './product-selection.component.html',
  styleUrls: ['./product-selection.component.css']
})

export class ProductSelectionComponent extends FormCanDeactivate implements OnInit, OnDestroy {
  @Output() nextEvent = new EventEmitter<number>();
  @ViewChild('formTwo', { static: false }) form: NgForm;

  productForm: FormGroup; // Product Phone Selection Form
  showlineNo: boolean; // On Select of Landline Option - Yes, show Landline No Input
  showCellOption = false; // On Select of Landline Option - No, show Cell Option
  showBackButton = false; // Show Back Button
  showCellNo: boolean; // On Select of Cell Option - Yes, show Cell No Input
  showConnections = true; // Show Form
  isNextButton = true; // Disable/Enable Next Button
  showNextButton = true;
  showExitButton = false;
  serviceLineValue = 0; // Radio button selection value
  serviceCellValue = 0;
  showCellular: boolean;
  showLandline: boolean;
  showSaveButton: boolean;
  showMobile: boolean;

  memberAffiliateId = '';
  memberProgramCode = '';
  memberDeviceType = '';
  disableAwayService = false;

  lineProds: Product[];
  cellProds: Product[];
  showReplacement: boolean;
  replaceProd: string;
  imageno: number;
  replaceTitle: string;
  replaceDesc: string;
  showSaveBackButton: boolean;
  isConnectionType: boolean;
  isProdSelect: boolean;
  isEmptyLineNo: boolean;
  isEmptyCellNo: boolean;

  productSubs: Subscription;
  saveSubs: Subscription;
  formProdSubs: Subscription;
  formCell: Subscription;
  formLine: Subscription;
  memberDetailsSubs: Subscription;
  gpsLabelInd: number = null; // Index with GPS label
  dialogRef: MatDialogRef<ConfirmModalComponent>;

  prodPhoneNo: string; prodExt: string;

  private destroy$: Subject<boolean> = new Subject<boolean>();
  private savedAwayServiceSelected: boolean; // Original awayServiceSelected value from Edit Modal

  constructor(
    private productFormBuilder: FormBuilder,
    private formsService: FormsService,
    private prodModalService: NgbModal,
    private prodRoute: Router,
    private matDialog: MatDialog
  ) {
    super();
    this.showCellular = false;
    this.showLandline = false;
    this.showlineNo = false;
    this.showMobile = false;
    this.formsService.careDetails$.pipe(takeUntil(this.destroy$)).subscribe(careDetails => {
      if (careDetails) {
        this.memberAffiliateId = careDetails.affiliateid;
        this.disableAwayService = careDetails.disableAwayService;
      }
    });
    this.formsService.tokenInformation$.pipe(takeUntil(this.destroy$)).subscribe(tokenResponse => {
      if (tokenResponse.code) {
        this.memberProgramCode = tokenResponse.code;
      }

      if (tokenResponse.deviceType) {
        this.memberDeviceType = tokenResponse.deviceType;
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit() {
    this.productSubs = this.formsService.productSelection().subscribe(products => {
      this.lineProds = products.line.filter(product => product.stock !== false);
      this.cellProds = products.cell.filter(product => product.stock !== false);
    });

    this.initializeProductForm();

    this.showReplacement = false;
    this.showSaveBackButton = false;
    this.showCellNo = false;
    this.isConnectionType = false;
    this.isProdSelect = false;
    this.isEmptyLineNo = false;
    this.isEmptyCellNo = false;

    this.formsService.careDetails$.pipe(takeUntil(this.destroy$)).subscribe(careDetails => {
      if (careDetails) {
        this.prodPhoneNo = careDetails.phoneno;
        this.prodExt = careDetails.ext;
      }
    });

    this.formLine = this.productForm.controls.lineNo.valueChanges.subscribe(() => {
      this.isEmptyLineNo = ((!this.productForm.controls.lineNo ||
        this.productForm.controls.lineNo.value === '' ||
        this.productForm.controls.lineNo.value === null) &&
        this.productForm.controls.lineChoice.value === '1') ? true : false;
    });

    this.formCell = this.productForm.controls.cellNo.valueChanges.subscribe(() => {
      this.isEmptyCellNo = ((!this.productForm.controls.cellNo ||
        this.productForm.controls.cellNo.value === '' ||
        this.productForm.controls.cellNo.value === null) &&
        this.productForm.controls.cellChoice.value === '1') ? true : false;
    });

    this.productForm.controls.otgPlusSelected.valueChanges.subscribe(() => {
      if (this.productForm.controls.otgPlusSelected.value) {
        this.productForm.controls.otgAwayServiceSelected.enable();
      } else {
        this.productForm.controls.otgAwayServiceSelected.disable();
      }
    });

    if (this.formsService.productDetails) {
      this.productForm = this.formsService.productDetails;
    }

    this.saveSubs = this.formsService.showSaveButton$.subscribe(showSaveButton => {
      this.showSaveButton = showSaveButton;
    });

    // Modal Persist Values
    if (this.showSaveButton) {
      this.formProdSubs = this.formsService.formProductInfo$.subscribe(productInfo => {
        this.prodFormPatch(productInfo);
      });
    }
  }

  initializeProductForm() {
    this.productForm = this.productFormBuilder.group({
      lineNo: ['', Validators.pattern(/^\d{3}-\d{3}-\d{4}$/)],
      lineNoMatch: [''],
      cellNo: ['', Validators.pattern(/^\d{3}-\d{3}-\d{4}$/)],
      cellNoMatch: [''],
      lineChoice: ['', Validators.required],
      cellChoice: [''],
      serviceLineChoice: [''],
      serviceCellChoice: [''],
      otgPlusSelected: [false],
      otgAwayServiceSelected: [{
        value: false,
        disabled: true
      }],
      otgConsent: this.productFormBuilder.group({
        mobSelected: [false],
        awayServiceSelected: [false],
      })
    }, {
      validator: [
        matchField('lineNo', 'lineNoMatch'), matchField('cellNo', 'cellNoMatch'),
        this.mobilityConsentValidator.bind(this),
        this.awayConsentValidator.bind(this)
      ]
    });
  }

  /**
   * @method: mobilityConsentValidator
   * @returns: { any } an error object if form is invalid else return null
   * @description: Validate that mobility consent is selected if the selected product is On the Go+.
   */
  mobilityConsentValidator = (formGroup: FormGroup) => {
    if (!formGroup) {
      return;
    }

    if (formGroup.value.otgPlusSelected && (!formGroup.value.otgConsent || !formGroup.value.otgConsent.mobSelected)) {
      return { mobilityConsentRequired: true };
    } else {
      return null;
    }
  };

  /**
   * @method: awayConsentValidator
   * @returns: { any } an error object if form is invalid else return null
   * @description: Validate that away service consent is selected if the selected product is On the Go+ with Away Service.
   */
  awayConsentValidator = (formGroup: FormGroup) => {
    if (!formGroup) {
      return;
    }

    if (formGroup.value.otgAwayServiceSelected && (!formGroup.value.otgConsent || !formGroup.value.otgConsent.awayServiceSelected)) {
      return { awayConsentRequired: true };
    } else {
      return null;
    }
  };

  showPrevious() {
    this.showConnections = true;
    this.showlineNo = false;
    this.showCellOption = false;
    this.showCellNo = false;
    this.showBackButton = false;
    this.showExitButton = false;
    this.showCellular = false;
    this.showMobile = false;
    this.showLandline = false;
    this.showSaveBackButton = false;
  }

  /**
   * On Change/Select of Radio Group Choice for Landine connection (Yes/No)
   */
  lineChoiceSelection(lineChoiceEvent: string) {
    if (lineChoiceEvent === '1') {
      this.showlineNo = true;
      this.showBackButton = false;
      this.showCellNo = false;
      this.serviceLineValue = 1;
      this.highlightSelectedProduct(this.productForm.controls.serviceLineChoice.value, this.productForm.controls.serviceCellChoice.value);
      this.showSaveBackButton = false;
      this.isConnectionType = false;
      if (!this.productForm.controls.cellNo.valid) {
        this.productForm.controls.cellNo.setValue('');
        this.productForm.controls.cellNoMatch.setValue('');
      }
    } else if (lineChoiceEvent === '2') {
      this.showlineNo = false;
      this.showCellOption = true;
      this.showBackButton = true;
      this.showCellNo = false;
      this.showCellular = false;
      this.showSaveBackButton = false;
      this.isConnectionType = (this.productForm.controls.cellChoice.value === '' ||
        this.productForm.controls.cellChoice.value === null) ? true : false;
      this.cellChoiceSelection(this.productForm.controls.cellChoice.value);
    }
  }

  cellChoiceSelection(cellChoiceEvent: string) {
    if (cellChoiceEvent === '1') {
      this.showCellNo = true;
      this.showExitButton = false;
      this.showSaveBackButton = true;
      this.serviceCellValue = 1;
      this.highlightSelectedProduct(this.productForm.controls.serviceLineChoice.value, this.productForm.controls.serviceCellChoice.value);
      this.showlineNo = false;
      if (!this.productForm.controls.lineNo.valid) {
        this.productForm.controls.lineNo.setValue('');
        this.productForm.controls.lineNoMatch.setValue('');
      }
    } else if (cellChoiceEvent === '2') {
      this.showCellNo = false;
      this.showExitButton = true;
      this.showLandline = false;
      this.showSaveBackButton = false;
      this.showlineNo = false;
    }
    this.showBackButton = true;
    this.isConnectionType = false;
  }

  /**
   * Radio Button Selection for Landline Products
   * Sets the Style as highlights the product when selected
   */
  serviceLineChoiceSelection(serviceLineEvent: number) {
    this.productForm.controls.serviceCellChoice.reset();
    this.serviceCellValue = 0;
    this.filterProducts(serviceLineEvent, this.lineProds);
    this.serviceLineValue = serviceLineEvent;
    this.showReplacement = false;
    this.isProdSelect = false;
    this.productForm.controls.otgPlusSelected.setValue(false);
    this.productForm.controls.otgAwayServiceSelected.setValue(false);
    this.productForm.controls.otgConsent.setValue({
      mobSelected: false,
      awayServiceSelected: false,
    });
  }

  serviceCellChoiceSelection(serviceCellEvent: number) {
    this.productForm.controls.serviceLineChoice.reset();
    this.serviceLineValue = 0;
    this.serviceCellValue = serviceCellEvent;
    this.isProdSelect = false;
    this.showReplacement = false;
    const selectedProduct = this.filterProducts(serviceCellEvent, this.cellProds);
    this.productForm.controls.otgPlusSelected.setValue(selectedProduct.prodTitle === 'On the Go+');
    this.productForm.controls.otgAwayServiceSelected.setValue(false);
    this.productForm.controls.otgConsent.setValue({
      mobSelected: false,
      awayServiceSelected: false,
    });
  }

  goToFormInfo() {
    this.checkFormValidations();
    this.checkFormValid();
    if (!this.isConnectionType && !this.isProdSelect && this.productForm.valid) {
      this.formsService.formProductInfo$.next(this.productForm);
      this.nextEvent.emit();
    }
  }

  saveProductInfo() {
    this.checkFormValidations();
    this.checkFormValid();
    if (!this.isConnectionType && !this.isProdSelect && this.productForm.valid) {
      this.formsService.formProductInfo$.next(this.productForm);
      this.showlineNo = false;
      this.showCellNo = false;
      this.prodModalService.dismissAll();
    }
  }

  checkFormValidations() {
    this.productForm.markAllAsTouched();

    const lineChoice = this.productForm.controls.lineChoice.value;
    const cellChoice = this.productForm.controls.cellChoice.value;

    const lineNo = this.productForm.get('lineNo');

    if (lineChoice === '1') {
      lineNo.setValidators(Validators.required);
    } else {
      lineNo.clearValidators();
      const cellNo = this.productForm.get('cellNo');
      if (cellChoice === '1') {
        cellNo.setValidators(Validators.required);
      } else {
        cellNo.clearValidators();
      }
      cellNo.updateValueAndValidity();
    }
    lineNo.updateValueAndValidity();

    this.isConnectionType = (((this.productForm.controls.lineChoice.value === '' ||
      this.productForm.controls.lineChoice.value === null) &&
      (this.productForm.controls.cellChoice.value === '' ||
        this.productForm.controls.cellChoice.value === null)) ||
      (this.productForm.controls.lineChoice.value === '2' &&
        (this.productForm.controls.cellChoice.value === '' ||
          this.productForm.controls.cellChoice.value === null ||
          this.productForm.controls.cellChoice.value === '2'))) ? true : false;

    this.isEmptyLineNo = ((this.productForm.controls.lineNo.value === '' ||
      this.productForm.controls.lineNo.value === null) &&
      this.productForm.controls.lineChoice.value === '1') ? true : false;
    this.isEmptyCellNo = ((this.productForm.controls.cellNo.value === '' ||
      this.productForm.controls.cellNo.value === null) &&
      this.productForm.controls.cellChoice.value === '1' &&
      this.productForm.controls.lineChoice.value === '2') ? true : false;

    this.isProdSelect = ((this.productForm.controls.serviceLineChoice.value === null &&
      this.productForm.controls.serviceCellChoice.value === null) ||
      ((this.productForm.controls.serviceLineChoice.value === '' ||
        this.productForm.controls.serviceLineChoice.value === null) &&
        (this.productForm.controls.serviceCellChoice.value === '' ||
          this.productForm.controls.serviceCellChoice.value === null))) ? true : false;
  }

  checkFormValid() {
    if (this.productForm.controls.cellChoice.value === '1' && this.productForm.controls.lineChoice.value === '2') {
      this.productForm.controls.lineNo.setValue('');
      this.productForm.controls.lineNoMatch.setValue('');
    } else if (this.productForm.controls.cellChoice.value === '1' && this.productForm.controls.lineChoice.value === '1') {
      this.productForm.controls.cellNo.setValue('');
      this.productForm.controls.cellNoMatch.setValue('');
    }
  }

  openProdModal(
    productid: number,
    lineType: boolean,
    isReplacement: boolean,
    image?: string,
    prodDetails?: ProductModal,
    replacementDetails?: ProductReplacement
  ) {
    const productModal = this.prodModalService.open(ModalComponent, { size: 'lg', centered: true, backdrop: 'static' });
    productModal.componentInstance.isProdModal = true;
    productModal.componentInstance.modalData = (prodDetails === undefined) ? '' : prodDetails[0];
    productModal.componentInstance.prodImage = (image === '') ? replacementDetails[0].image : image;
    productModal.componentInstance.isCell = lineType;
    productModal.componentInstance.prodID = productid;
    productModal.componentInstance.isReplace = isReplacement;
    productModal.componentInstance.replacement = (replacementDetails === undefined) ? '' : replacementDetails[0];

    // Close Modal for Product Replacement - Option No
    productModal.componentInstance.closeReplacement.subscribe(() => {
      productModal.close();
      const exitModal = this.prodModalService.open(ModalComponent, { size: 'lg', centered: true, backdrop: 'static' });
      exitModal.componentInstance.isExitModal = true;

      // Back to Product Selection on exit Product Replacement - Option Back
      exitModal.componentInstance.backProduct.subscribe(() => {
        this.formsService.productInfo$.next(null);
        this.productForm.controls.serviceCellChoice.reset();
        this.serviceCellValue = 0;
        this.showReplacement = false;
        exitModal.close();
      });

      // Exit App on exit Product Replacement - Option Exit
      exitModal.componentInstance.exit.subscribe(() => {
        this.exitForm();
      });

      // Select for Product Replacement - Option Yes
      exitModal.componentInstance.closeExit.subscribe(() => {
        this.formsService.productInfo$.next(null);
        this.productForm.controls.serviceCellChoice.reset();
        this.serviceCellValue = 0;
        this.showReplacement = false;
        exitModal.close();
      });
    });

    // Select for Product Replacement - Option Yes
    productModal.componentInstance.selectReplacement.subscribe((emitProd: any) => {
      const prodSelected: ProductReplacement = emitProd.replacementProd;
      this.formsService.productInfo$.next({
        prodTitle: prodSelected.title,
        prodDescription: prodSelected.stockUpdate,
        prodImage: prodSelected.image,
        prodNo: prodSelected.prodNo,
        prodMonthlyRate: prodSelected.price,
        bundleNo: prodSelected.bundleNo
      });
      this.productForm.controls.serviceCellChoice.setValue(emitProd.idNo);
      productModal.close();
    });

    // Select for Product Replacement - Option Yes
    productModal.componentInstance.closeReplace.subscribe(() => {
      this.formsService.productInfo$.next(null);
      this.productForm.controls.serviceCellChoice.reset();
      this.serviceCellValue = 0;
      this.showReplacement = false;
      productModal.close();
    });
  }

  productReplacementSelection(index: number) {
    this.serviceCellValue = index;
    this.showReplacement = true;
    this.imageno = index - 1;
    this.formsService.productInfo$.subscribe(info => {
      this.replaceTitle = info.prodTitle;
      this.replaceDesc = info.prodDescription;
      this.replaceProd = info.prodImage;
    });
  }

  filterProducts(value: number, filterValue: any) {
    const matchingProduct: Product = filterValue.find((items: any) => items.id === value);

    this.formsService.productInfo$.next({
      prodTitle: matchingProduct.prodTitle,
      prodDescription: matchingProduct.prodDescription,
      prodImage: matchingProduct.prodImage,
      prodNo: matchingProduct.prodNo,
      prodMonthlyRate: matchingProduct.prodMonthlyRate,
      bundleNo: matchingProduct.bundleNo
    });
    return matchingProduct;
  }

  prodFormPatch(prodValues: FormGroup) {
    this.initializeProductForm();

    if (!prodValues) {
      return;
    }

    this.productForm.patchValue({
      lineNo: prodValues.controls.lineNo.value,
      lineNoMatch: prodValues.controls.lineNoMatch.value,
      cellNo: prodValues.controls.cellNo.value,
      cellNoMatch: prodValues.controls.cellNoMatch.value,
      lineChoice: prodValues.controls.lineChoice.value,
      cellChoice: prodValues.controls.cellChoice.value,
      serviceLineChoice: prodValues.controls.serviceLineChoice.value,
      serviceCellChoice: prodValues.controls.serviceCellChoice.value,
      otgPlusSelected: prodValues.controls.otgPlusSelected.value,
      otgAwayServiceSelected: prodValues.controls.otgAwayServiceSelected.value,
      otgConsent: prodValues.controls.otgConsent.value
    });
    this.savedAwayServiceSelected = prodValues.controls.otgAwayServiceSelected.value;
    if (this.productForm.controls.serviceCellChoice.value > 0) {
      this.serviceCellValue = this.productForm.controls.serviceCellChoice.value;
    } else {
      this.serviceCellValue = 0;
    }
    this.lineChoiceSelection(this.productForm.controls.lineChoice.value);
    this.highlightSelectedProduct(this.productForm.controls.serviceLineChoice.value, this.productForm.controls.serviceCellChoice.value);
  }

  highlightSelectedProduct(lineid: number, cellid: number) {
    if (cellid > 0) {
      this.serviceCellValue = cellid;
      this.serviceLineValue = 0;
    } else if (lineid > 0) {
      this.serviceCellValue = 0;
      this.serviceLineValue = lineid;
    }
  }

  exitForm() {
    const providerRoute = this.prodRoute.url.split('/')[1];
    this.formsService.clearForms();
    this.prodModalService.dismissAll();
    this.prodRoute.navigateByUrl(`${providerRoute}`);
  }

  // GPS Product should not be displayed for certain HUMANA members, if their ProgramCode is LIF006
  setShowMobile(deviceType: string): boolean {
    // Member's provider name and program code are received as messages in the constructor. Check if they meet the criteria
    this.showMobile = false;
    if (this.memberAffiliateId && this.memberAffiliateId.toUpperCase() === 'HUMANA') {
      if (this.memberDeviceType.toLowerCase().includes('on the go')) {
        this.showMobile = true;
      } else if (deviceType.toUpperCase() !== 'GPS') {
        this.showMobile = true;
      } else {
        this.showMobile = false;
      }
    } else {
      this.showMobile = true;
    }
    return this.showMobile;
  }

  /**
   * @method isFirstGPS
   * @param index of current GPS product
   * @description Sets index of first GPS product as index with GPS label.
   *  Returns true if current index has the GPS label
   */
  isFirstGPS(index) {
    if (this.gpsLabelInd == null) {
      this.gpsLabelInd = index;
    }
    return this.gpsLabelInd === index;
  }

  /**
   * @method checkCaregiverFormUpdates
   * @description If Away Service is selected, show modal to confirm that
   *  Away Service Contact is required in Caregiver Form. Updates
   *  Caregiver Form validation on confirmation or undoes selection on cancel.
   *  Else if not selected, show modal to confirm Away Service Contact roles
   *  will be deleted in Caregiver Form. Deletes role on confirmation or
   *  undoes selection on cancel.
   */
  checkCaregiverFormUpdates() {
    const isAwayServiceSelected = this.productForm.controls.otgAwayServiceSelected.value;
    if (this.savedAwayServiceSelected === isAwayServiceSelected) {
      this.saveProductInfo();
    } else {
      if (isAwayServiceSelected) {
        const title = 'Away Service Contact Is Required';
        const message = 'Please add a caregiver with an Away Service Contact role in the Caregiver Information section.';
        this.dialogRef = this.matDialog.open(ConfirmModalComponent, {
          width: '800px',
          autoFocus: false,
          disableClose: true,
          data: { title, message, error: true }
        });
        const dialogCancelSubscription = this.dialogRef.componentInstance.cancel
          .subscribe(() => {
            this.productForm.controls.otgAwayServiceSelected.setValue(this.savedAwayServiceSelected);
            this.updateAwayService(this.savedAwayServiceSelected);
            dialogCancelSubscription.unsubscribe();
          });
        const dialogSubmitSubscription = this.dialogRef.componentInstance.confirm
          .subscribe(() => {
            this.formsService.validateCaregiverForm();
            dialogSubmitSubscription.unsubscribe();
            this.saveProductInfo();
          });
      } else {
        const title = 'Away Service Contact Roles Will Be Removed';
        const message = 'The Away Service Contact role(s) will be removed in the Caregiver Information section. ' +
          'Away Service Contacts are only needed when you select On the Go+ with Away Service.';
        this.dialogRef = this.matDialog.open(ConfirmModalComponent, {
          width: '800px',
          autoFocus: false,
          disableClose: true,
          data: { title, message }
        });
        const dialogCancelSubscription = this.dialogRef.componentInstance.cancel
          .subscribe(() => {
            this.productForm.controls.otgAwayServiceSelected.setValue(this.savedAwayServiceSelected);
            dialogCancelSubscription.unsubscribe();
          });
        const dialogSubmitSubscription = this.dialogRef.componentInstance.confirm
          .subscribe(() => {
            dialogSubmitSubscription.unsubscribe();
            this.saveProductInfo();
          });
      }
    }
  }

  // Unchecks Away Service consent if Away Service option if unselected
  updateAwayService(isAwayServiceSelected) {
    if (!isAwayServiceSelected) {
      const otgConsent: FormGroup = this.productForm.controls.otgConsent as FormGroup;
      otgConsent.controls.awayServiceSelected.setValue(false);
    }
  }

  awayServiceEnabled() {
    return !this.disableAwayService;
  }
}
