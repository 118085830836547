import { Component, OnInit, ViewChild, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';

import { VaisFormsService } from '../../services';
import { ModalComponent } from '../../../shared/components';
import { FormCanDeactivate } from 'src/app/authguard/form-can-deactivate';
import { ProductModal } from 'src/app/shared/models/product-modal.model';
import { matchField } from 'src/app/validators/match-field.validator';
import { Product } from '../../../shared/models/product.model';
import { FormsService } from 'src/app/enrollment-forms/services';
import { AppConfig } from 'src/app/shared/services/app-config.service';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { MatDialog } from '@angular/material';
import vaisproducts from '../../../../assets/json/vaisproducts.json';


@Component({
  selector: 'app-vais-product-selection',
  templateUrl: './vais-product-selection.component.html',
  styleUrls: ['./vais-product-selection.component.css']
})

export class VaisProductSelectionComponent extends FormCanDeactivate implements OnInit, OnDestroy {
  @Output() nextEvent = new EventEmitter<number>();
  @ViewChild('formTwo', { static: false }) form: NgForm;

  productForm: FormGroup;
  previouslySavedForm: FormGroup;

  visibleDevices: Product[];
  viewAsModal: boolean;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private productFormBuilder: FormBuilder,
    private vaisFormsService: VaisFormsService,
    private formsService: FormsService,
    private prodModalService: NgbModal,
    private prodRoute: Router,
    private appConfig: AppConfig,
    private matDialog: MatDialog
  ) {
    super();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit() {
    this.initializeProductForm();

    this.vaisFormsService.viewAsModal$.subscribe(viewAsModal => {
      this.viewAsModal = viewAsModal;
    });

    this.productForm.controls.otgPlusSelected.valueChanges.subscribe(() => {
      if (this.productForm.controls.otgPlusSelected.value) {
        this.productForm.controls.otgAwayServiceSelected.enable();
      } else {
        this.productForm.controls.otgAwayServiceSelected.disable();
      }
    });

    if (this.vaisFormsService.productDetails) {
      this.productForm = this.vaisFormsService.productDetails;
    }

    this.vaisFormsService.formProductInfo$.subscribe(productInfo => {
      this.previouslySavedForm = productInfo;
      this.productForm.patchValue(productInfo.getRawValue());
      this.showApplicableDevices();
      this.productForm.updateValueAndValidity();
    });
  }

  initializeProductForm() {
    this.productForm = this.productFormBuilder.group({
      serviceType: ['', Validators.required],
      phoneType: [''],
      phoneNo: ['', [Validators.required, Validators.pattern(/^\d{3}-\d{3}-\d{4}$/)]],
      confirmPhoneNo: ['', Validators.required],
      workingLandline: [false],
      selectedDevice: [null, Validators.required],
      otgPlusSelected: [false],
      otgAwayServiceSelected: [{
        value: false,
        disabled: true
      }],
      otgConsent: this.productFormBuilder.group({
        mobSelected: [false],
        awayServiceSelected: [false],
      })
    }, {
      validator: [
        matchField('phoneNo', 'confirmPhoneNo'),
        (formGroup: FormGroup): ValidationErrors | null =>
          formGroup && formGroup.value.otgPlusSelected &&
            (!formGroup.value.otgConsent || !formGroup.value.otgConsent.mobSelected) ?
            { mobilityConsentRequired: true } : null,
        (formGroup: FormGroup): ValidationErrors | null =>
          formGroup && formGroup.value.otgAwayServiceSelected &&
            (!formGroup.value.otgConsent || !formGroup.value.otgConsent.awayServiceSelected) ?
            { awayConsentRequired: true } : null
      ]
    });
  }

  showApplicableDevices() {
    this.productForm.controls.selectedDevice.markAsUntouched();
    this.productForm.controls.phoneNo.markAsUntouched();
    this.productForm.controls.confirmPhoneNo.markAsUntouched();

    switch (this.productForm.controls.serviceType.value) {
      case this.vaisFormsService.serviceTypes.inHome:
        this.productForm.controls.phoneType.updateValueAndValidity();
        this.productForm.controls.workingLandline.updateValueAndValidity();
        switch (this.productForm.controls.phoneType.value) {
          case this.vaisFormsService.phoneTypes.landline:
            this.productForm.controls.workingLandline.setValidators(Validators.requiredTrue);
            this.productForm.controls.workingLandline.updateValueAndValidity();
            this.productForm.markAllAsTouched();
            this.visibleDevices = this.productForm.controls.workingLandline.value ? vaisproducts.line : [];
            break;
          case this.vaisFormsService.phoneTypes.cell:
            this.productForm.controls.workingLandline.clearValidators();
            this.productForm.controls.workingLandline.updateValueAndValidity();
            this.visibleDevices = vaisproducts.cell.filter(device => device.deviceType !== 'GPS');
            break;
          default:
            this.visibleDevices = [];
        }
        break;
      case this.vaisFormsService.serviceTypes.gpsMobile:
        this.productForm.controls.phoneType.clearValidators();
        this.productForm.controls.workingLandline.clearValidators();
        this.productForm.controls.phoneType.updateValueAndValidity();
        this.productForm.controls.workingLandline.updateValueAndValidity();
        this.visibleDevices = vaisproducts.cell.filter(device => device.deviceType === 'GPS');
        this.productForm.controls.phoneType.setValue('');
        break;
      default:
        this.visibleDevices = [];
    }
  }

  deviceSelected(device: number) {
    const selectedProduct: Product = this.visibleDevices.find((items: any) => items.id === device);

    this.vaisFormsService.productInfo$.next({
      prodTitle: selectedProduct.prodTitle,
      prodDescription: selectedProduct.prodDescription,
      prodImage: selectedProduct.prodImage,
      prodNo: selectedProduct.prodNo,
      prodMonthlyRate: selectedProduct.prodMonthlyRate,
      bundleNo: selectedProduct.bundleNo
    });

    this.productForm.controls.otgPlusSelected.setValue(selectedProduct.prodTitle === 'On the Go+');
    this.productForm.controls.otgAwayServiceSelected.setValue(false);
    this.productForm.controls.otgConsent.setValue({
      mobSelected: false,
      awayServiceSelected: false,
    });
  }

  goToFormInfo() {
    this.productForm.updateValueAndValidity();
    this.productForm.markAllAsTouched();

    if (this.productForm.valid) {
      this.vaisFormsService.formProductInfo$.next(this.productForm);
      this.nextEvent.emit();
    }
  }

  openProdModal(
    productid: number,
    serviceType: boolean,
    isReplacement: boolean,
    image?: string,
    prodDetails?: ProductModal
  ) {
    const productModal = this.prodModalService.open(ModalComponent, { size: 'lg', centered: true, backdrop: 'static' });
    productModal.componentInstance.isProdModal = true;
    productModal.componentInstance.modalData = (prodDetails === undefined) ? '' : prodDetails[0];
    productModal.componentInstance.prodImage = image;
    productModal.componentInstance.isCell = serviceType;
    productModal.componentInstance.prodID = productid;
    productModal.componentInstance.isReplace = isReplacement;
  }

  exitForm() {
    const providerRoute = this.prodRoute.url.split('/')[1];
    this.formsService.clearForms();
    this.vaisFormsService.clearForms();
    this.prodModalService.dismissAll();
    this.prodRoute.navigateByUrl(`${providerRoute}`);
  }

  stringifyIfDevelopment(object: any) {
    return this.appConfig.config.production ? '' : JSON.stringify(object);
  }

  updateFromModal() {
    const savedAwayServiceSelected = this.previouslySavedForm.controls.otgAwayServiceSelected.value;
    const isAwayServiceSelected = this.productForm.controls.otgAwayServiceSelected.value;
    if (savedAwayServiceSelected === isAwayServiceSelected) {
      this.saveProductInfoFromModal();
    } else {
      if (isAwayServiceSelected) {
        const title = 'Away Service Contact Is Required';
        const message = 'Please add a caregiver with an Away Service Contact role in the Caregiver Information section.';
        const dialogRef = this.matDialog.open(ConfirmModalComponent, {
          width: '800px',
          autoFocus: false,
          disableClose: true,
          data: { title, message, error: true }
        });
        const dialogCancelSubscription = dialogRef.componentInstance.cancel
          .subscribe(() => {
            this.productForm.controls.otgAwayServiceSelected.setValue(savedAwayServiceSelected);
            this.updateAwayService(savedAwayServiceSelected);
            dialogCancelSubscription.unsubscribe();
          });
        const dialogSubmitSubscription = dialogRef.componentInstance.confirm
          .subscribe(() => {
            this.formsService.validateCaregiverForm();
            dialogSubmitSubscription.unsubscribe();
            this.saveProductInfoFromModal();
          });
      } else {
        const title = 'Away Service Contact Roles Will Be Removed';
        const message = 'The Away Service Contact role(s) will be removed in the Caregiver Information section. ' +
          'Away Service Contacts are only needed when you select On the Go+ with Away Service.';
        const dialogRef = this.matDialog.open(ConfirmModalComponent, {
          width: '800px',
          autoFocus: false,
          disableClose: true,
          data: { title, message }
        });
        const dialogCancelSubscription = dialogRef.componentInstance.cancel
          .subscribe(() => {
            this.productForm.controls.otgAwayServiceSelected.setValue(savedAwayServiceSelected);
            dialogCancelSubscription.unsubscribe();
          });
        const dialogSubmitSubscription = dialogRef.componentInstance.confirm
          .subscribe(() => {
            dialogSubmitSubscription.unsubscribe();
            this.saveProductInfoFromModal();
          });
      }
    }
  }

  saveProductInfoFromModal() {
    this.productForm.updateValueAndValidity();
    if (this.productForm.valid) {
      this.vaisFormsService.formProductInfo$.next(this.productForm);
      this.prodModalService.dismissAll();
    }
  }

  updateAwayService(isAwayServiceSelected) {
    if (!isAwayServiceSelected) {
      const otgConsentGroup: FormGroup = this.productForm.controls.otgConsent as FormGroup;
      otgConsentGroup.controls.awayServiceSelected.setValue(false);
    }
  }
}
