import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, NgForm, ValidationErrors, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, TimeoutError } from 'rxjs';
import { yearAndMonthIsFutureValidator } from 'src/app/validators/year-and-month-is-future.validator';

import * as moment from 'moment';
import gender from '../../../../assets/json/gender.json';
import languages from '../../../../assets/json/languages.json';
import states from '../../../../assets/json/states.json';
import { AuthService } from 'src/app/shared/services/auth.service';
import { VaisFormsService } from '../../services';
import { matchField } from '../../../validators/match-field.validator';
import { FormCanDeactivate } from 'src/app/authguard/form-can-deactivate';
import { SearchService } from 'src/app/eligibility-search/service/search.service';
import { AppConfig } from 'src/app/shared/services/app-config.service';
import { AwsRumFunctions } from 'src/app/shared/aws-rum-functions.service';
import { creditCardValidator } from 'src/app/validators/credit-card.validator';
import { monthValidator } from 'src/app/validators/month.validator';
import { yearRangeValidator } from 'src/app/validators/year.validator';
import { Product } from 'src/app/shared/models/product.model';
import { SmartyStreetsResponse } from 'src/app/shared/models/smarty-streets-response.model';
import { State } from 'src/app/shared/models/state.model';

@Component({
  selector: 'app-vais-member-information',
  templateUrl: './vais-member-information.component.html',
  styleUrls: ['./vais-member-information.component.css']
})

export class VaisMemberInformationComponent extends FormCanDeactivate implements OnInit, OnDestroy {
  @Output() nextEvent = new EventEmitter<number>();
  @Output() previousEvent = new EventEmitter<number>();
  @ViewChild('formThree', { static: false }) form: NgForm;

  memberForm: FormGroup;
  languages = languages;
  states = states.sort((a: State, b: State) =>
    a.name < b.name ? -1 :
    a.name > b.name ? 1 : 0);
  genders = gender;
  userProducts: Product[] = [];

  viewAsModal: boolean;
  showPoBoxErrMsg = false;

  isValidating = false;
  isLoggedIn: boolean;
  isFootnoteVisible = false;

  suggestedAddress: string = null;
  suggestedCity: string = null;
  suggestedState: string = null;
  suggestedZipCode: string = null;

  cannotReachAddressValidationService = false;
  unknownAddress = false;

  badAddress: string = null;
  badCity: string = null;
  badState: string = null;
  badZipCode: string = null;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  private routingNumberValidators = [Validators.required, Validators.pattern(/^\d{9}$/)];
  private accountNumberValidators = [Validators.required, Validators.pattern(/^\d{8,17}$/)];
  private phoneNumberValidators = [
    Validators.required,
    Validators.pattern(/^\d{10}$/),
    Validators.minLength(10),
    Validators.maxLength(10)
  ];

  private cardholderNameValidators = [
    Validators.required,
    Validators.pattern(/^([A-Za-z\-\']+ )+[A-Za-z\-\']+$|^[A-Za-z\-\']+$/),
    Validators.minLength(3),
    Validators.maxLength(40)
  ];
  private streetValidators = [
    Validators.required,
    Validators.minLength(3),
    Validators.maxLength(30)
  ];
  private address2Validators = [
    Validators.maxLength(30)
  ];
  private cityValidators = [
    Validators.required,
    Validators.pattern(/^([A-Za-z\-\']+ )+[A-Za-z\-\']+$|^[A-Za-z\-\']+$/),
    Validators.minLength(3),
    Validators.maxLength(40)
  ];
  private stateValidators = [
    Validators.required,
    Validators.pattern(/^([A-Za-z\-\']+ )+[A-Za-z\-\']+$|^[A-Za-z\-\']+$/),
    Validators.minLength(2),
    Validators.maxLength(2)
  ];
  private zipCodeValidators = [
    Validators.required,
    Validators.pattern(/^[0-9]{5}(-[0-9]{4})?$/),
    Validators.minLength(5)
  ];
  private cardNumberValidators = [
    Validators.required,
    Validators.pattern(/^\d{13,19}$/),
    creditCardValidator()
  ];
  private expirationDateMonthValidators = [
    Validators.required,
    Validators.pattern(/^\d{2}$/),
    monthValidator()
  ];
  private expirationDateYearValidators = [
    Validators.required,
    Validators.pattern(/^\d{4}$/),
    yearRangeValidator(new Date().getFullYear(), new Date().getFullYear() + 10)
  ];

  constructor(
    private appConfig: AppConfig,
    private memberFormBuilder: FormBuilder,
    private authService: AuthService,
    private vaisFormsService: VaisFormsService,
    private memberModal: NgbModal,
    private searchService: SearchService
  ) {
    super();

    this.vaisFormsService.productInfo$.subscribe((product: Product) => {
      if (product) {
        this.userProducts = [product];
      }
    });
  }

  ngOnInit() {
    this.memberForm = this.memberFormBuilder.group({
      firstName: [
        '',
        [
          Validators.required,
          Validators.pattern(/^([A-Za-z\-\']+ )+[A-Za-z\-\']+$|^[A-Za-z\-\']+$/),
          Validators.minLength(3),
          Validators.maxLength(40)
        ]
      ],
      middleName: [
        '',
        [
          Validators.pattern(/^([A-Za-z\-\']+ )+[A-Za-z\-\']+$|^[A-Za-z\-\']+$/),
          Validators.maxLength(40)
        ]
      ],
      lastName: [
        '',
        [
          Validators.required,
          Validators.pattern(/^([A-Za-z\-\']+ )+[A-Za-z\-\']+$|^[A-Za-z\-\']+$/),
          Validators.minLength(3),
          Validators.maxLength(40)
        ]
      ],
      address1: [
        '',
        [
          Validators.required,
          (): ValidationErrors | null =>
            this.suggestedAddress ? { correctedAddressSuggested: this.suggestedAddress } : null,
          (): ValidationErrors | null =>
            this.unknownAddress ? { unknownAddress: true } : null,
          (): ValidationErrors | null =>
            this.cannotReachAddressValidationService ? { cannotReachAddressValidationService: true } : null,
          (): ValidationErrors | null =>
            this.showPoBoxErrMsg ? { isPoBox: true } : null
        ]
      ],
      address2: [''],
      city: [
        '',
        [
          Validators.required, ,
          (): ValidationErrors | null =>
            this.suggestedCity ? { correctedAddressSuggested: this.suggestedCity } : null
        ]
      ],
      state: [
        '',
        [
          Validators.required,
          (): ValidationErrors | null =>
            this.suggestedState ? { correctedAddressSuggested: this.suggestedState } : null
        ]
      ],
      zipCode: [
        '',
        [
          Validators.required,
          (): ValidationErrors | null =>
            this.suggestedZipCode ? { correctedAddressSuggested: this.suggestedZipCode } : null
        ]
      ],
      addressOverride: [false],
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
        ]
      ],
      emailMatch: ['', Validators.required],
      phone: [
        { value: '', disabled: true },
        [Validators.required, Validators.pattern(/^\d{3}-\d{3}-\d{4}$/)]
      ],
      language: ['', Validators.required],
      gender: ['', Validators.required],
      dob: [
        { value: '', disabled: true },
        Validators.required
      ],
      houseKeyExists: [false, Validators.required],
      locationOfHouseKey: [''],

      differentShippingAddress: [false],
      shippingFirstName: [''],
      shippingLastName: [''],
      shippingAddress1: [''],
      shippingAddress2: [''],
      shippingCity: [''],
      shippingState: [''],
      shippingZipCode: [''],
      shippingPhoneNumber: [''],

      isAccountHolder: [null, Validators.required],
      payerFirstName: ['',
        [
          Validators.required,
          Validators.pattern(/^([A-Za-z\-\']+ )+[A-Za-z\-\']+$|^[A-Za-z\-\']+$/),
          Validators.minLength(3),
          Validators.maxLength(40)
        ]
      ],
      payerLastName: ['',
        [
          Validators.required,
          Validators.pattern(/^([A-Za-z\-\']+ )+[A-Za-z\-\']+$|^[A-Za-z\-\']+$/),
          Validators.minLength(3),
          Validators.maxLength(40)
        ]
      ],
      paymentPhoneNumber: ['', this.phoneNumberValidators],
      isPayingByACH: [null, Validators.required],
      routingNumber: ['', null],
      accountNumber: ['', null],
      cardholderName: ['', this.cardholderNameValidators],
      paymentAddress1: ['', this.streetValidators],
      paymentAddress2: ['', this.address2Validators],
      paymentCity: ['', this.cityValidators],
      paymentState: ['', this.stateValidators],
      paymentZipCode: ['', this.zipCodeValidators],
      cardNumber: ['', this.cardNumberValidators],
      expirationDateMonth: ['', this.expirationDateMonthValidators],
      expirationDateYear: ['', this.expirationDateYearValidators],
      paymentAddressOverride: [false]
    },
      {
        validators: [
          matchField('email', 'emailMatch'),
          yearAndMonthIsFutureValidator('expirationDateMonth', 'expirationDateYear')
        ]
      }
    );

    this.memberForm.controls.differentShippingAddress.valueChanges.subscribe((value: boolean) => {
      if (value) {
        this.setValidatorsForShipping(this.memberForm.controls);
      } else {
        this.clearValidatorsForShipping(this.memberForm.controls);
      }
    });

    this.memberForm.controls.firstName.valueChanges.subscribe(() => {
      if (this.memberForm.controls.isAccountHolder.value) {
        this.setPayerAddressToSubscriberAddress();
      }
    });

    this.memberForm.controls.lastName.valueChanges.subscribe(() => {
      if (this.memberForm.controls.isAccountHolder.value) {
        this.setPayerAddressToSubscriberAddress();
      }
    });

    this.memberForm.controls.address1.valueChanges.subscribe(() => {
      if (this.memberForm.controls.isAccountHolder.value) {
        this.setPayerAddressToSubscriberAddress();
      }
      this.checkIfBadAddressChanged();
      if (this.suggestedAddress && this.memberForm.controls.address1.value.toLowerCase() === this.suggestedAddress.toLowerCase()) {
        this.suggestedAddress = null;
        this.updateAllAddressValidators(this.memberForm.controls);
      }
    });

    this.memberForm.controls.address2.valueChanges.subscribe(() => {
      if (this.memberForm.controls.isAccountHolder.value) {
        this.setPayerAddressToSubscriberAddress();
      }
    });

    this.memberForm.controls.city.valueChanges.subscribe(() => {
      if (this.memberForm.controls.isAccountHolder.value) {
        this.setPayerAddressToSubscriberAddress();
      }
      this.checkIfBadAddressChanged();
      if (this.suggestedCity && this.memberForm.controls.city.value.toLowerCase() === this.suggestedCity.toLowerCase()) {
        this.suggestedCity = null;
        this.updateAllAddressValidators(this.memberForm.controls);
      }
    });

    this.memberForm.controls.state.valueChanges.subscribe(() => {
      if (this.memberForm.controls.isAccountHolder.value) {
        this.setPayerAddressToSubscriberAddress();
      }
      this.checkIfBadAddressChanged();
      if (this.suggestedState && this.memberForm.controls.state.value.toLowerCase() === this.suggestedState.toLowerCase()) {
        this.suggestedState = null;
        this.updateAllAddressValidators(this.memberForm.controls);
      }
    });

    this.memberForm.controls.zipCode.valueChanges.subscribe(() => {
      if (this.memberForm.controls.isAccountHolder.value) {
        this.setPayerAddressToSubscriberAddress();
      }
      this.checkIfBadAddressChanged();
      if (this.suggestedZipCode && this.memberForm.controls.zipCode.value === this.suggestedZipCode) {
        this.suggestedZipCode = null;
        this.updateAllAddressValidators(this.memberForm.controls);
      }
    });

    this.memberForm.controls.phone.valueChanges.subscribe(() => {
      if (this.memberForm.controls.isAccountHolder.value) {
        this.setPayerAddressToSubscriberAddress();
      }
    });

    this.memberForm.controls.addressOverride.valueChanges.subscribe(() => {
      if (this.memberForm.controls.addressOverride.value) {
        this.suggestedAddress = null;
        this.suggestedCity = null;
        this.suggestedState = null;
        this.suggestedZipCode = null;
      };
      this.updateAllAddressValidators(this.memberForm.controls);
    });

    this.vaisFormsService.formMemberVerification$.subscribe(memberVerification => {
      if (memberVerification && memberVerification.controls && Object.keys(memberVerification.controls).length > 0) {
        const memberDate = memberVerification.controls.dob.value;
        this.memberForm.patchValue({
          firstName: memberVerification.controls.firstName.value,
          lastName: memberVerification.controls.lastName.value,
          dob: moment([memberDate.year, memberDate.month - 1, memberDate.day]).format('YYYY-MM-DD')
        });
      }
    });

    this.vaisFormsService.formProductInfo$.subscribe(phoneFormResponse => {
      if (phoneFormResponse && phoneFormResponse.controls && Object.keys(phoneFormResponse.controls).length > 0) {
        this.memberForm.patchValue({
          phone: phoneFormResponse.controls.phoneNo.value
        });
      }
    });

    this.vaisFormsService.viewAsModal$.subscribe(viewAsModal => {
      this.viewAsModal = viewAsModal;
    });

    if (this.viewAsModal) {
      this.vaisFormsService.formMemberInfo$.subscribe(memberInformation => {
        if (memberInformation) {
          this.memberFormPatch(memberInformation);
        }
      });
    }

    if (this.vaisFormsService.memberInfoDetails) {
      this.memberForm = this.vaisFormsService.memberInfoDetails;
    }

    this.authService.isLoggedIn$().subscribe((isLoggedIn) => {
      this.isLoggedIn = isLoggedIn;
    });

    if (!this.viewAsModal) {
      const selectedCustomer = this.searchService.getSelectedCustomer();

      if (!this.memberForm.controls.address1.value && selectedCustomer) {
        if (selectedCustomer.addrLine1) {
          this.memberForm.controls.address1.setValue(selectedCustomer.addrLine1.toUpperCase());
        }
  
        if (selectedCustomer.addrLine2) {
          this.memberForm.controls.address2.setValue(selectedCustomer.addrLine2.toUpperCase());
        }
  
        if (selectedCustomer.city) {
          this.memberForm.controls.city.setValue(selectedCustomer.city.toUpperCase());
        }
  
        this.memberForm.controls.state.setValue(this.abbreviateState(selectedCustomer.state));
        this.memberForm.controls.zipCode.setValue(selectedCustomer.zip);
      }
      }

    this.checkSelection();
  }

  setPayerAddressToSubscriberAddress() {
    const controls = this.memberForm.controls;

    controls.payerLastName.setValue(controls.lastName.value);
    controls.payerFirstName.setValue(controls.firstName.value);
    controls.paymentAddress1.setValue(controls.address1.value);
    controls.paymentAddress2.setValue(controls.address2.value);
    controls.paymentCity.setValue(controls.city.value);
    controls.paymentState.setValue(controls.state.value);
    controls.paymentZipCode.setValue(controls.zipCode.value);
    controls.paymentPhoneNumber.setValue(controls.phone.value);
  }

  checkSelection() {
    const controls = this.memberForm.controls;

    controls.houseKeyExists.valueChanges
      .subscribe(houseKeyExist => {
        if (houseKeyExist) {
          controls.locationOfHouseKey.setValidators([Validators.required, Validators.pattern(/\S/)]);
        } else {
          controls.locationOfHouseKey.clearValidators();
          controls.locationOfHouseKey.updateValueAndValidity();
        }
      });

    controls.isAccountHolder.valueChanges
      .subscribe(isAccountHolder => {
        if (isAccountHolder) {
          this.setPayerAddressToSubscriberAddress();
          controls.payerLastName.disable();
          controls.payerFirstName.disable();
          controls.paymentAddress1.disable();
          controls.paymentAddress2.disable();
          controls.paymentCity.disable();
          controls.paymentState.disable();
          controls.paymentZipCode.disable();
          controls.paymentPhoneNumber.disable();
        } else {
          controls.payerLastName.enable();
          controls.payerFirstName.enable();
          controls.paymentAddress1.enable();
          controls.paymentAddress2.enable();
          controls.paymentCity.enable();
          controls.paymentState.enable();
          controls.paymentZipCode.enable();
          controls.paymentPhoneNumber.disable();
        }
      });

    controls.isPayingByACH.valueChanges
      .subscribe(isPayingByACH => {
        this.setValidatorsByPaymentMethod(isPayingByACH);
      });
  }

  checkFormAddresses() {
    const enteredStreet = this.memberForm.controls.address1.value;
    const enteredCity = this.memberForm.controls.city.value;
    const enteredState = this.memberForm.controls.state.value;
    const enteredZipCode = this.memberForm.controls.zipCode.value;

    if (!enteredStreet || !enteredCity || !enteredState || !enteredZipCode) {
      this.isValidating = false;
      return;
    }

    this.vaisFormsService.getFullAddressInfo(enteredStreet, enteredCity, enteredState, enteredZipCode)
      .subscribe((matchingAddresses: SmartyStreetsResponse[]) => {
        this.isValidating = false;
        this.suggestedAddress = null;
        this.suggestedCity = null;
        this.suggestedState = null;
        this.showPoBoxErrMsg = false;

        if (matchingAddresses instanceof TimeoutError || matchingAddresses instanceof HttpErrorResponse) {
          if (this.isLoggedIn) {
            if (this.memberForm.controls.addressOverride.value) {
              const httpErrorMessage = matchingAddresses instanceof TimeoutError ?
                `Address verification resulted in HTTP timeout. Allowing logged-in user to continue, but investigate for service issues.` :
                matchingAddresses instanceof HttpErrorResponse ?
                  `Address verification resulted in HTTP error ${matchingAddresses.error.code} ${matchingAddresses.error.description}. ` +
                  `Allowing logged-in user to continue, but investigate for service issues.` : '';
              AwsRumFunctions.callCwr('recordError', httpErrorMessage);
              if (this.appConfig.config.debug) {
                console.error(httpErrorMessage);
              }
            }
          }
          this.updateAllAddressValidators(this.memberForm.controls);
          return;
        }

        this.cannotReachAddressValidationService = false;
    
        if (matchingAddresses.length === 0 || matchingAddresses[0] instanceof HttpErrorResponse) {
          this.badAddress = enteredStreet;
          this.badCity = enteredCity;
          this.badState = enteredState;
          this.badZipCode = enteredZipCode;
          this.unknownAddress = true;
          this.updateAllAddressValidators(this.memberForm.controls);
          return;
        }

        this.unknownAddress = false;
        const { delivery_line_1: correctedAddress } = matchingAddresses[0];
        const {
          street_name: correctedStreetName,
          city_name: correctedCity,
          state_abbreviation: correctedState,
          zipcode: correctedZipCode
        } = matchingAddresses[0].components;

        if (correctedAddress && correctedAddress.toUpperCase() !== enteredStreet.toUpperCase()) {
          this.badAddress = enteredStreet;
          this.suggestedAddress = correctedAddress;
        }

        if (correctedStreetName === 'PO Box') {
          this.showPoBoxErrMsg = true;
          this.badAddress = enteredStreet;
        }

        if (correctedCity && correctedCity.toUpperCase() !== enteredCity.toUpperCase()) {
          this.badCity = enteredCity;
          this.suggestedCity = correctedCity;
        }

        if (correctedState && correctedState.toUpperCase() !== enteredState.toUpperCase()) {
          this.badState = enteredState;
          this.suggestedState = correctedState;
        }

        if (correctedZipCode && correctedZipCode !== enteredZipCode) {
          this.badZipCode = enteredZipCode;
          this.suggestedZipCode = correctedZipCode;
        }

        this.updateAllAddressValidators(this.memberForm.controls);
        this.save();
      }, (errorResponse: unknown) => {
        this.isValidating = false;
        if (this.isLoggedIn) {
          if (this.memberForm.controls.addressOverride.value) {
            const httpErrorMessage = errorResponse instanceof TimeoutError ?
              `Error reaching address validation service. Allowing logged-in user to continue, but investigate for service issues.` :
              errorResponse instanceof HttpErrorResponse ?
                `Address verification resulted in HTTP error ${errorResponse.error.code} ${errorResponse.error.description}. ` +
                `Allowing logged-in user to continue, but investigate for service issues.` : '';
            AwsRumFunctions.callCwr('recordError', httpErrorMessage);
            if (this.appConfig.config.debug) {
              console.error(httpErrorMessage);
            }
          }
        } else {
          this.cannotReachAddressValidationService = true;
          this.badAddress = enteredStreet;
          this.badCity = enteredCity;
          this.badState = enteredState;
          this.badZipCode = enteredZipCode;

          this.memberForm.updateValueAndValidity();
        }
        this.updateAllAddressValidators(this.memberForm.controls);
        return;
      });
  }

  save() {
    this.memberForm.markAllAsTouched();
    this.memberForm.updateValueAndValidity();
    if (this.memberForm.valid) {
      this.vaisFormsService.formMemberInfo$.next(this.memberForm);
      if (!this.viewAsModal) {
        this.nextEvent.emit();
      } else {
        this.memberModal.dismissAll();
      }
    }
  }

  goToFormCare() {
    this.isValidating = true;
    this.memberForm.markAllAsTouched();
    this.checkFormAddresses();
  }

  goToFormProd() {
    this.previousEvent.emit(1);
  }

  memberFormPatch(memberValues: FormGroup) {
    if (!memberValues || !memberValues.controls) {
      return;
    }

    this.memberForm.patchValue(memberValues.getRawValue());
  }

  setValidatorsByPaymentMethod(isPayingByACH: boolean) {
    const controls = this.memberForm.controls;

    if (isPayingByACH) {
      this.setValidatorsForAch(controls);
    } else {
      this.setValidatorsForCreditCard(controls);
    }

    this.updateAllPaymentValidators(controls);
  }

  toggleFootnote(state: boolean = null) {
    if (state === null) {
      this.isFootnoteVisible = !this.isFootnoteVisible;
    } else {
      this.isFootnoteVisible = state;
    }
  }

  stringifyIfDevelopment(object: any) {
    return this.appConfig.config.production ? '' : JSON.stringify(object);
  }

  checkIfBadAddressChanged() {
    if (this.unknownAddress || this.cannotReachAddressValidationService) {
      if (this.memberForm.controls.address1.value !== this.badAddress ||
        this.memberForm.controls.city.value !== this.badCity ||
        this.memberForm.controls.state.value !== this.badState ||
        this.memberForm.controls.zipCode.value !== this.badZipCode
      ) {
        this.unknownAddress = false;
        this.cannotReachAddressValidationService = false;
        this.suggestedAddress = null;
        this.updateAllAddressValidators(this.memberForm.controls);
      }
    }
  }

  ngOnDestroy() {
    this.vaisFormsService.memberInfoDetails = this.memberForm;
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setValidatorsForShipping(controls: { [key: string]: AbstractControl }) {
    controls.shippingFirstName.setValidators([
        Validators.required,
        Validators.pattern(/^([A-Za-z\-\']+ )+[A-Za-z\-\']+$|^[A-Za-z\-\']+$/),
        Validators.minLength(3),
        Validators.maxLength(40)
      ]);
    controls.shippingLastName.setValidators([
        Validators.required,
        Validators.pattern(/^([A-Za-z\-\']+ )+[A-Za-z\-\']+$|^[A-Za-z\-\']+$/),
        Validators.minLength(3),
        Validators.maxLength(40)
      ]);
    controls.shippingAddress1.setValidators(this.streetValidators);
    controls.shippingAddress2.setValidators(this.address2Validators);
    controls.shippingCity.setValidators(this.cityValidators);
    controls.shippingState.setValidators(this.stateValidators);
    controls.shippingZipCode.setValidators(this.zipCodeValidators);
    controls.shippingPhoneNumber.setValidators(this.phoneNumberValidators);

    this.updateShippingValuesAndValidity(controls);
  }

  private clearValidatorsForShipping(controls: { [key: string]: AbstractControl }) {
    controls.shippingFirstName.clearValidators();
    controls.shippingLastName.clearValidators();
    controls.shippingAddress1.clearValidators();
    controls.shippingAddress2.clearValidators();
    controls.shippingCity.clearValidators();
    controls.shippingState.clearValidators();
    controls.shippingZipCode.clearValidators();
    controls.shippingPhoneNumber.clearValidators();

    this.updateShippingValuesAndValidity(controls);
  }

  private updateShippingValuesAndValidity(controls: { [key: string]: AbstractControl }) {
    controls.shippingFirstName.updateValueAndValidity();
    controls.shippingLastName.updateValueAndValidity();
    controls.shippingAddress1.updateValueAndValidity();
    controls.shippingAddress2.updateValueAndValidity();
    controls.shippingCity.updateValueAndValidity();
    controls.shippingState.updateValueAndValidity();
    controls.shippingFirstName.updateValueAndValidity();
    controls.shippingZipCode.updateValueAndValidity();
    controls.shippingPhoneNumber.updateValueAndValidity();
  }

  private setValidatorsForAch(controls: { [key: string]: AbstractControl }) {
    controls.routingNumber.setValidators(this.routingNumberValidators);
    controls.accountNumber.setValidators(this.accountNumberValidators);
    controls.paymentPhoneNumber.setValidators(this.phoneNumberValidators);
    controls.cardholderName.clearValidators();
    controls.paymentAddress1.setValidators(this.streetValidators);
    controls.paymentAddress2.setValidators(this.address2Validators);
    controls.paymentCity.setValidators(this.cityValidators);
    controls.paymentState.setValidators(this.stateValidators);
    controls.paymentZipCode.setValidators(this.zipCodeValidators);
    controls.cardNumber.clearValidators();
    controls.expirationDateYear.clearValidators();
    controls.expirationDateMonth.clearValidators();
  }

  private setValidatorsForCreditCard(controls: { [key: string]: AbstractControl }) {
    controls.routingNumber.clearValidators();
    controls.accountNumber.clearValidators();
    controls.paymentPhoneNumber.setValidators(this.phoneNumberValidators);
    controls.cardholderName.setValidators(this.cardholderNameValidators);
    controls.paymentAddress1.setValidators(this.streetValidators);
    controls.paymentAddress2.setValidators(this.address2Validators);
    controls.paymentCity.setValidators(this.cityValidators);
    controls.paymentState.setValidators(this.stateValidators);
    controls.paymentZipCode.setValidators(this.zipCodeValidators);
    controls.cardNumber.setValidators(this.cardNumberValidators);
    controls.expirationDateMonth.setValidators(this.expirationDateMonthValidators);
    controls.expirationDateYear.setValidators(this.expirationDateYearValidators);
  }

  private updateAllPaymentValidators(controls: { [key: string]: AbstractControl }) {
    controls.routingNumber.updateValueAndValidity();
    controls.accountNumber.updateValueAndValidity();
    controls.cardholderName.updateValueAndValidity();
    controls.paymentAddress1.updateValueAndValidity();
    controls.paymentAddress2.updateValueAndValidity();
    controls.paymentCity.updateValueAndValidity();
    controls.paymentState.updateValueAndValidity();
    controls.paymentZipCode.updateValueAndValidity();
    controls.cardNumber.updateValueAndValidity();
    controls.expirationDateMonth.updateValueAndValidity();
    controls.expirationDateYear.updateValueAndValidity();
  }

  private updateAllAddressValidators(controls: { [key: string]: AbstractControl }) {
    controls.address1.updateValueAndValidity();
    controls.city.updateValueAndValidity();
    controls.state.updateValueAndValidity();
    controls.zipCode.updateValueAndValidity();
  }

  private abbreviateState(fullStateName: string): string {
    const matchingState = states.find((state: State) => state.fullName === fullStateName);
    if (matchingState) {
      return matchingState.name;
    } else {
      return fullStateName;
    }
  }
}
