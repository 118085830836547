import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxCaptchaModule } from 'ngx-captcha';

import {
  VaisStepperControlComponent, VaisCouponVerificationComponent,
  VaisSubHeaderComponent, VaisProductSelectionComponent, VaisMemberInformationComponent,
  VaisCaregiverInfoComponent, VaisReviewOrderComponent, VaisFormModalComponent, VaisSuccessPageComponent
} from './components';
import { MaterialModule } from '../material.module';
import { SharedModule } from '../shared';

@NgModule({
  declarations: [
    VaisStepperControlComponent,
    VaisCouponVerificationComponent,
    VaisSubHeaderComponent,
    VaisProductSelectionComponent,
    VaisMemberInformationComponent,
    VaisCaregiverInfoComponent,
    VaisReviewOrderComponent,
    VaisFormModalComponent,
    VaisSuccessPageComponent
  ],
  imports: [
    CommonModule, MaterialModule, NgbModule, ReactiveFormsModule, FormsModule,
    SharedModule, NgSelectModule,
    NgxCaptchaModule
  ],
  providers: [
    TitleCasePipe
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [
    VaisStepperControlComponent, VaisSubHeaderComponent, VaisCouponVerificationComponent,
    VaisProductSelectionComponent, VaisMemberInformationComponent, VaisCaregiverInfoComponent,
    VaisFormModalComponent, VaisSuccessPageComponent
  ],
  entryComponents: [VaisFormModalComponent]
})

export class VaisEnrollmentFormsModule { }
