import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SearchService } from 'src/app/eligibility-search/service/search.service';
import { FormsService } from 'src/app/enrollment-forms/services';

@Component({
  selector: 'app-vai-slanding-page',
  templateUrl: './vais-landing-page.component.html',
  styleUrls: ['./vais-landing-page.component.css']
})

export class VaisLandingPageComponent implements OnInit, OnDestroy {
  isFireFox: boolean;
  isSafari: boolean;
  isIEorEdge: boolean;
  isChrome: boolean;
  isOpera: boolean;
  providerType: string;
  providerName: any;
  providerPhone: string;
  providerExt: string;
  affiliateid: string;
  affiliateName: string;
  message: string;
  enrollmentDisabled = false;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private router: Router,
    private formsService: FormsService,
    private searchService: SearchService
  ) {
    this.isChrome = /Chrome/.test(navigator.userAgent) &&
      !/Chromium/.test(navigator.userAgent) &&
      !/Edg/i.test(navigator.userAgent);
    this.isIEorEdge = /msie\s|trident\/|edge\//.test(navigator.userAgent);
    this.isFireFox = /Firefox/.test(navigator.userAgent) &&
      !/Seamonkey/.test(navigator.userAgent);
    this.isOpera = /Opera|OPR/.test(navigator.userAgent);
    this.isSafari = /Safari/.test(navigator.userAgent) &&
      !/Chrome/.test(navigator.userAgent);

    this.searchService.clearSelectedCustomer();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit() {
    this.formsService.careDetails$.pipe(takeUntil(this.destroy$)).subscribe(careDetails => {
      if (careDetails) {
        this.providerName = careDetails.carename;
        this.providerPhone = careDetails.phoneno;
        this.providerExt = careDetails.ext;
        this.affiliateid = careDetails.affiliateid;
        this.affiliateName = careDetails.name;
      }
    });
  }

  redirectToEnrollmentForms(): void {
    this.router.navigateByUrl(`${this.router.url}/enrollmentforms`);
  }
}
