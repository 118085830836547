import { Component, EventEmitter, OnInit, Output, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, NgForm, Validators } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { VaisFormsService } from '../../services';
import { FormCanDeactivate } from 'src/app/authguard/form-can-deactivate';
import { SearchService } from 'src/app/eligibility-search/service/search.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Provider } from 'src/app/enrollment-forms/models/provider.model';
import { couponCodeValidator } from 'src/app/validators/coupon-code.validator';
import { FormsService } from 'src/app/enrollment-forms/services';
import { AppConfig } from 'src/app/shared/services/app-config.service';

@Component({
  selector: 'app-vais-coupon-verification',
  templateUrl: './vais-coupon-verification.component.html',
  styleUrls: ['./vais-coupon-verification.component.css']
})

export class VaisCouponVerificationComponent extends FormCanDeactivate implements OnInit, OnDestroy {
  @Output() nextEvent = new EventEmitter<number>();
  @ViewChild('formOne', { static: false }) form: NgForm;

  couponForm: FormGroup;
  isRepLoggedIn = false;
  provider: Provider = {
    carename: '',
    cardimage: '',
    phoneno: '',
    convertPhoneNo: '',
    ext: '',
    name: '',
    affiliateid: '',
    externalid: '',
    vaisCouponCodes: [],
    vaisImage: '',
  };

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private authService: AuthService,
    private vaisFormsService: VaisFormsService,
    private formsService: FormsService,
    private couponFormBuilder: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private searchService: SearchService,
    private appConfig: AppConfig
  ) {
    super();
  }

  ngOnInit() {
    this.authService.isLoggedIn$().subscribe(isRepLoggedIn => {
      this.isRepLoggedIn = isRepLoggedIn;
    });

    this.formsService.clearForms();
    this.vaisFormsService.clearForms();
    this.vaisFormsService.useDefaultLogo$.next(false);

    this.formsService.careDetails$.pipe(takeUntil(this.destroy$)).subscribe((provider: Provider) => {
      if (provider) {
        this.provider = provider;
      }
    });

    const selectedCustomer = this.searchService.getSelectedCustomer();
    const dataFromEnrollment = this.formsService.getVerificationDataForVais();

    const namePattern = /^([A-Za-z\-\']+ )+[A-Za-z\-\']+$|^[A-Za-z\-\']+$/;

    this.couponForm = this.couponFormBuilder.group({
      lastName: [
        dataFromEnrollment ? dataFromEnrollment.lastName :
        selectedCustomer ? selectedCustomer.lastName.toUpperCase() : '',
        {
          validators: [
            Validators.required,
            Validators.pattern(namePattern),
            Validators.minLength(3),
            Validators.maxLength(40)
          ]
        }],
      firstName: [
        dataFromEnrollment ? dataFromEnrollment.firstName :
        selectedCustomer ? selectedCustomer.firstName.toUpperCase() : '', {
          validators: [
            Validators.required,
            Validators.pattern(namePattern),
            Validators.minLength(2),
            Validators.maxLength(40)
          ]
        }],
      dob: [
        dataFromEnrollment ? dataFromEnrollment.dob :
        selectedCustomer ? this.stringToNgbDateStruct(selectedCustomer.mbrDob.toString()) : null,
        Validators.required
      ],
      zipCode: [
        dataFromEnrollment ? dataFromEnrollment.zipCode :
        selectedCustomer ? selectedCustomer.zip : '',
        [
          Validators.required,
          Validators.pattern(/^[0-9]{5}$/)
        ]
      ],
      couponCode: [
        '',
        [
          Validators.required,
          Validators.pattern(/^[A-Za-z0-9]+$/),
          couponCodeValidator(this.provider)
        ]
      ],
      hasServiceAlready: [
        {
          value: this.isRepLoggedIn ? 'no' : null,
          disabled: this.isRepLoggedIn
        },
        [
          Validators.required,
          Validators.pattern('no')
        ]
      ]
    });

    this.changeDetectorRef.detectChanges();
  }

  submitMemberForm() {
    this.vaisFormsService.formMemberVerification$.next(this.couponForm);
    this.nextEvent.emit();
  }

  stringifyIfDevelopment(object: any) {
    return this.appConfig.config.production ? '' : JSON.stringify(object);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private stringToNgbDateStruct(dateString: string): NgbDateStruct {
    return this.dateToNgbDateStruct(new Date(dateString));
  }

  private dateToNgbDateStruct(date: Date): NgbDateStruct {
    return {
      year: date.getUTCFullYear(),
      month: date.getUTCMonth() + 1,
      day: date.getUTCDate()
    };
  }
}
