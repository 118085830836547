import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription, fromEvent } from 'rxjs';

import { ModalComponent } from './shared/components';
import { TimeoutService } from './shared/services/timeout.service';
import { MatDialog } from '@angular/material';
import { KeepAliveModalComponent } from './shared/components/keep-alive-modal/keep-alive-modal.component';
import { ConfigManagerLoginComponent } from './config-manager-login/config-manager-login.component';
import { AuthService } from './shared/services/auth.service';
import { AppConfig } from './shared/services/app-config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  onlineEvent$: Observable<Event>; // Online Connection
  offlineEvent$: Observable<Event>; // Offline Connection
  subscriptions: Subscription[] = [];

  isFireFox: boolean;
  isSafari: boolean;
  isIEorEdge: boolean;
  isChrome: boolean;
  isOpera: boolean;
  mobileSupported: boolean;
  mobileNotSupported: boolean;

  title = 'Lifeline - Self Service Enrollment';

  constructor(
    private connectionModalService: NgbModal,
    private timeoutService: TimeoutService,
    private dialog: MatDialog,
    private authService: AuthService,
    private appConfig: AppConfig
  ) { }

  ngOnInit() {
    // Browser Compatibility
    this.isFireFox = /firefox|OPR/i.test(navigator.userAgent);
    this.isSafari = /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);
    this.isIEorEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    this.isChrome = /Google Inc/.test(navigator.vendor);
    this.isOpera = /OPR/.test(navigator.userAgent);
    this.mobileSupported = /IEMobile|CriOS/i.test(navigator.userAgent);
    this.mobileNotSupported = /Opera Mini|FxiOS|OPiOS/i.test(navigator.userAgent);

    this.onlineEvent$ = fromEvent(window, 'online');
    this.offlineEvent$ = fromEvent(window, 'offline');

    // Online Modal Dismiss
    this.subscriptions.push(this.onlineEvent$.subscribe(() => {
      this.connectionModalService.dismissAll();
    }));

    // Offline Modal Show
    this.subscriptions.push(this.offlineEvent$.subscribe(() => {
      const connectionStatusModal = this.connectionModalService.open(ModalComponent, { size: 'lg', centered: true, backdrop: 'static' });
      connectionStatusModal.componentInstance.modalTitle = 'Connection Status';
      connectionStatusModal.componentInstance.modalContent = 'Internet connectivity lost';
      connectionStatusModal.componentInstance.showDismiss = false;
      connectionStatusModal.componentInstance.isProdModal = false;
    }));

    this.timeoutService.timeoutTriggered$.subscribe(() => {
      this.openTimeoutDialog();
    });

    this.timeoutService.freshLoginNecessary$.subscribe(() => {
      this.openLogInAgainDialog();
    });

    this.logoutWhenLoadingSite();
  }

  openTimeoutDialog() {
    this.dialog.open(KeepAliveModalComponent, {
      autoFocus: false,
      disableClose: true
    });
  }

  openLogInAgainDialog() {
    this.dialog.open(ConfigManagerLoginComponent, {
      autoFocus: false,
      disableClose: true
    });
  }

  ngOnDestroy(): void { }

  private logoutWhenLoadingSite(): void {
    if (!localStorage.getItem(this.appConfig.config.localStorageLastActivity)) {
      return;
    }

    const referrer = document.referrer;
    const currentOrigin = window.location.origin;

    if (!referrer || !referrer.startsWith(currentOrigin)) {
      this.authService.logout();
    }
  }
}
