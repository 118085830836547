import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { MatDialogRef } from '@angular/material';
import { BehaviorSubject, interval, Observable, Subject, Subscription } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

const COUNTDOWN_IN_SECONDS = 15;

@Component({
  selector: 'app-keep-alive-modal',
  templateUrl: './keep-alive-modal.component.html',
  styleUrls: ['./keep-alive-modal.component.css']
})
export class KeepAliveModalComponent implements OnInit, OnDestroy {
  countdownObservable$: Observable<number>;

  private countdown$ = new BehaviorSubject<number>(COUNTDOWN_IN_SECONDS);
  private timerSubscription: Subscription;
  private stopCountdown$ = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<KeepAliveModalComponent>,
    private authService: AuthService,
    private router: Router
  ) {
    this.countdownObservable$ = this.countdown$.asObservable();
  }

  ngOnInit() {
    this.startCountdown();
  }

  startCountdown() {
    this.timerSubscription = interval(1000)
      .pipe(
        tap(() => {
          const currentValue = this.countdown$.value;
          if (currentValue > 0) {
            this.countdown$.next(currentValue - 1);
          } else {
            this.logout();
          }
        }),
        takeUntil(this.stopCountdown$)
      ).subscribe();
  }

  logout() {
    this.authService.logout();
    this.stopCountdown$.next();
    this.dialogRef.close();
    this.router.navigateByUrl(`/configmanager-login`);
  }

  refreshSession() {
    this.stopCountdown$.next();
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }
}
