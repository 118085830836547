import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, fromEvent, merge, Observable, Subject, timer } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';

import { VaisFormsService } from '../../services/index.js';
import { AppConfig } from 'src/app/shared/services/app-config.service';
import { FormsService } from 'src/app/enrollment-forms/services/forms.service.js';

@Component({
  selector: 'app-vais-sub-header',
  templateUrl: './vais-sub-header.component.html',
  styleUrls: ['./vais-sub-header.component.css']
})

export class VaisSubHeaderComponent implements OnInit, OnDestroy {
  @Input() headerText: string | undefined;
  checkOnline: boolean;
  startTimer: boolean;
  countDown;
  timerDuration: number;
  timerValue: number;
  tick: number;

  isLogo: boolean;
  careLogo: string;
  showLargeImage: boolean;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private vaisFormsService: VaisFormsService,
    private formsService: FormsService,
    public router: Router,
    private modals: NgbModal,
    private appConfig: AppConfig
  ) { }

  createOnline$(): Observable<boolean> {
    return merge(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable<boolean>(sub => {
        sub.next(navigator.onLine);
        sub.complete();
      }));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit() {
    this.showLargeImage = false;
    this.createOnline$().subscribe(_ => this.checkOnline = _);
    this.timerDuration = this.appConfig.config.timerDuration;
    this.timerValue = this.appConfig.config.timerValue;
    this.tick = 1000;
    this.startTimer = this.router.url.indexOf('enrollmentforms') > -1;
    this.countDown = timer(0, this.tick).pipe(
      take(this.timerDuration),
      map(() => {
        if (this.checkOnline) {
          this.timerDuration--;
          if (this.timerDuration === this.timerValue) {
            alert('You have 10 minutes left to fill the form');
          } else if (this.timerDuration === 0) {
            alert('You are being navigated to home page');
            this.formsService.clearForms();
            this.vaisFormsService.clearForms();
            this.modals.dismissAll();
            const providerRoute = this.router.url.split('/')[1];
            this.router.navigateByUrl(`${providerRoute}`);
          } else {
            return this.timerDuration;
          }
        } else {
          return this.timerDuration;
        }
      }),
    );

    // Get the Provider details
    combineLatest(this.formsService.careDetails$, this.vaisFormsService.useDefaultLogo$).pipe(takeUntil(this.destroy$))
      .subscribe(results => {
        const careDetails = results[0];
        const useDefaultLogo = results[1];

        if (!careDetails) {
          return;
        }

        if (careDetails.defaultlogo && useDefaultLogo) {
          this.careLogo = careDetails.defaultlogo;
        } else if (careDetails.carelogo) {
          this.careLogo = careDetails.carelogo;
        } else {
          this.careLogo = careDetails.logoLocationUrl;
        }

        if (this.careLogo && this.careLogo === '/assets/images/UHC-PH-Logo.png') {
          this.showLargeImage = true;
        } else {
          this.showLargeImage = false;
        }
      });
  }
}
