import { Component, OnInit, OnDestroy } from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { VaisFormsService } from '../../services';
import { SearchService } from 'src/app/eligibility-search/service/search.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { FormsService } from 'src/app/enrollment-forms/services';

@Component({
  selector: 'app-vais-success-page',
  templateUrl: './vais-success-page.component.html',
  styleUrls: ['./vais-success-page.component.css']
})
export class VaisSuccessPageComponent implements OnInit, OnDestroy {
  memberName = 'New member';
  phoneno: string;
  isRepLoggedIn = false;
  didEmailSend: boolean;
  hasSubmitted: boolean;
  isSubmitting: boolean;
  emailForm: FormGroup;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private vaisFormsService: VaisFormsService,
    private formsService: FormsService,
    private camelCase: TitleCasePipe,
    private emailFormBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private searchService: SearchService
  ) { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit() {
    this.authService.isLoggedIn$().subscribe(isRepLoggedIn => {
      this.isRepLoggedIn = isRepLoggedIn;
    });

    this.formsService.careDetails$.pipe(takeUntil(this.destroy$)).subscribe(careDetails => {
      if (careDetails) {
        this.phoneno = careDetails.phoneno + ' ' + careDetails.ext;
      }
    });

    if (this.vaisFormsService.lastSubmittedApplicant.firstName) {
      this.memberName = this.camelCase.transform(this.vaisFormsService.lastSubmittedApplicant.firstName);
    }

    this.emailForm = this.emailFormBuilder.group({
      email: ['',
        [
          Validators.required,
          Validators.email,
          Validators.pattern(/^[A-Za-z0-9_.%+-]{1,}@[A-Za-z0-9-]{1,}[.]{1}[A-Za-z.]{2,}$/)
        ]
      ],
    });

    this.emailForm.patchValue({
      email: this.vaisFormsService.lastSubmittedApplicant.email
    });

    this.isSubmitting = false;
    this.hasSubmitted = false;
    this.didEmailSend = false;
  }

  sendEmail() {
    if (this.emailForm.invalid) {
      this.emailForm.markAllAsTouched();
    } else {
      this.isSubmitting = true;
      const emailAddress = this.emailForm.controls.email.value;
      this.vaisFormsService.sendConfirmationEmail(emailAddress).subscribe(
        () => {
          this.isSubmitting = false;
          this.hasSubmitted = true;
          this.didEmailSend = true;
        }, () => {
          this.isSubmitting = false;
          this.hasSubmitted = true;
          this.didEmailSend = false;
        });
    }
  }

  showEmailForm() {
    this.hasSubmitted = false;
    this.didEmailSend = false;
    this.isSubmitting = false;
  }

  referredFromSearch(): boolean {
    return this.searchService.getSelectedCustomer() ? true : false;
  }

  searchAgain(): void {
    this.router.navigateByUrl('/eligibility-search');
  }
}
