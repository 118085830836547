import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';

import { AuthenticatePayload } from '../config-manager/model/authenticate-payload.model';
import { PayerConfigService } from '../config-manager/service/payerconfig.service';
import { FormsService } from '../enrollment-forms/services/forms.service';
import { AppConfig } from '../shared/services/app-config.service';
import { AuthService } from '../shared/services/auth.service';

@Component({
  selector: 'app-configmanager-login',
  templateUrl: './config-manager-login.component.html',
  styleUrls: ['./config-manager-login.component.css']
})
export class ConfigManagerLoginComponent implements OnInit {
  formInvalid: boolean;
  payerConfigLoginForm: FormGroup;
  hideErrorMessage: boolean;
  message: boolean;
  portals: any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private payerConfigService: PayerConfigService,
    private authService: AuthService,
    private formsServices: FormsService,
    private appConfig: AppConfig
  ) {
  }

  ngOnInit() {
    this.portals = [
      {
        name: 'Eligibility Search',
        url: 'eligibility-search'
      },
      {
        name: 'UHC (Convert)',
        url: 'uhc/convert'
      },
      {
        name: 'UHC (Enroll)',
        url: 'uhc'
      },
      {
        name: 'UHC (VAIS)',
        url: 'vais/uhc'
      },
      {
        name: 'UHC Retiree',
        url: 'uhcgr'
      },
      {
        name: 'UHC Retiree (VAIS)',
        url: 'vais/uhcgr'
      },
      {
        name: 'Humana',
        url: 'humana'
      },
      {
        name: 'Humana Group Medicare Advantage',
        url: 'humanagr'
      }
    ];

    this.payerConfigLoginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      portal: [this.portals[0].url, Validators.required]
    });
    this.payerConfigService.currentState$.subscribe(message => this.message = message);

    this.formsServices.unsetProvider();
  }

  onSubmit(): void {
    if (this.payerConfigLoginForm.dirty && this.payerConfigLoginForm.valid) {
      const userName = this.payerConfigLoginForm.controls.username.value;
      const userPassword = this.payerConfigLoginForm.controls.password.value;
      const firstName = this.payerConfigLoginForm.controls.firstName.value;
      const lastName = this.payerConfigLoginForm.controls.lastName.value;

      const credentials: AuthenticatePayload = {
        userName,
        userPassword
      };

      this.authService.authenticate$(credentials).subscribe(() => {
        localStorage.setItem(this.appConfig.config.localStorageRepUser, JSON.stringify({ userName, firstName, lastName }));
        this.authService.updateLoggedInStatus(true);

        const url = this.payerConfigLoginForm.controls.portal.value;
        this.router.navigateByUrl(`/` + url);

        const convertPattern = /\/convert$/;
        this.formsServices.setDefaultProvider(url.replace(convertPattern, ''), convertPattern.test(url));
      }, (error: unknown) => {
        this.formInvalid = true;
        this.hideErrorMessage = false;
        if (this.appConfig.config.debug && error instanceof HttpErrorResponse) {
          console.log(error);
          console.log(`Error: ${error.message}`);
        }
      });
    } else {
      this.formInvalid = true;
      this.hideErrorMessage = false;
      this.payerConfigService.changeLoggedInState(false);
    }
  }

  createHashString(strInput: string): any {
    return CryptoJS.SHA512(strInput);
  }

  fadeOutErrorMsg() {
    setTimeout(() => {
      this.hideErrorMessage = true;
    }, 4000);
  }
}
