import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';

import { Product } from 'src/app/shared/models/product.model';
import { AppConfig } from 'src/app/shared/services/app-config.service';
import { TraceIdService } from 'src/app/enrollment-forms/services/trace-id.service';
import { Account } from 'src/app/enrollment-forms/models/account.model';
import { Bundle } from 'src/app/shared/models/bundle.model';
import { VaisTokenInformation } from '../models/vais-token-information.model';
import { PlatformSubscriberPaymentRequest } from 'src/app/conversions/models/platform-subscriber-payment-request';
import { PaymentLineItem } from 'src/app/shared/models/payment-line-item.model';
import { PaymentInformation } from 'src/app/conversions/models/payment-information';
import { AuthService } from 'src/app/shared/services/auth.service';
import { PlatformPaymentRefusalResponse } from 'src/app/conversions/models/platform-payment-refusal-response';

import productbundle from '../../../assets/json/productbundle.json';

const careRecipientEmptyFormValues = {
  firstName: '',
  lastName: '',
  address1: '',
  address2: '',
  city: '',
  email: '',
  emailMatch: '',
  addressOverride: false,
  gender: '',
  phone: [],
  dob: '',
  houseKeyExists: false,
  language: '',
  locationOfHouseKey: '',
  middleName: '',
  state: '',
  zipCode: ''
};

const caregiverEmptyFormValues = {
  details: [
    {
      firstName: '',
      middleName: '',
      lastName: '',
      phoneDetails: [
        {
          carePhone: '',
          carePhoneType: ''
        }
      ],
      email: '',
      emailMatch: '',
      relationship: '',
      role: [],
      language: ''
    }
  ]
};

const productDetailsEmptyFormValues = {
  serviceType: '',
  phoneType: '',
  phoneNo: '',
  confirmPhoneNo: '',
  workingLandline: false,
  selectedDevice: '',
  otgPlusSelected: false,
  otgAwayServiceSelected: {
    value: false,
    disabled: true
  },
  otgConsent: {
    mobSelected: false,
    awayServiceSelected: false,
  }
};

interface LastSubmittedApplicant {
  firstName: string;
  email: string;
}

@Injectable({
  providedIn: 'root'
})
export class VaisFormsService {
  viewAsModal$ = new BehaviorSubject<boolean>(false);

  formMemberVerification$ = new BehaviorSubject<FormGroup>(new FormGroup({}));
  formMemberInfo$ = new BehaviorSubject<FormGroup>(new FormGroup({}));
  formProductInfo$ = new BehaviorSubject<FormGroup>(new FormGroup({}));
  formCaregiverInfo$ = new BehaviorSubject<FormGroup>(new FormGroup({}));
  formPayment: FormGroup;

  productInfo$ = new BehaviorSubject<Product>(null);
  tokenInformation$ = new BehaviorSubject<VaisTokenInformation>(new VaisTokenInformation());
  useDefaultLogo$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  validateCaregiverForm$$: Observable<boolean>;
  isCaregiverFormValid$: Observable<boolean>;

  membershipDetails: FormGroup;
  memberInfoDetails: FormGroup;
  productDetails: FormGroup;
  caregiverDetails: FormGroup;

  serviceTypes = {
    inHome: 'inHome',
    gpsMobile: 'gpsMobile'
  };

  phoneTypes = {
    landline: 'landline',
    cell: 'cell'
  };

  contactTypes = {
    responder: 'Responder',
    awayServiceContact: 'Away Service Contact',
    notify: 'Notify'
  };

  lastSubmittedApplicant: LastSubmittedApplicant = {
    firstName: '',
    email: ''
  };

  isLoggedIn = false;

  private validateCaregiverFormSource$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isCaregiverFormValidSource$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    private httpClient: HttpClient,
    private appConfig: AppConfig,
    private traceIdService: TraceIdService,
    private authService: AuthService
  ) {
    this.validateCaregiverForm$$ = this.validateCaregiverFormSource$.asObservable();
    this.isCaregiverFormValid$ = this.isCaregiverFormValidSource$.asObservable();

    this.authService.isLoggedIn$().subscribe((isLoggedIn) => {
      this.isLoggedIn = isLoggedIn;
    });
  }

  getFullAddressInfo(street: string, city: string, state: string, zip: string) {
    const fullAdressParams = {
      candidates: '1',
      street,
      city,
      state,
      zip
    };

    const httpOptions = { params: fullAdressParams };
    return this.httpClient.get(this.appConfig.config.addressApiStreetAddress, httpOptions);
  }

  createAccount$(accounts: Account[], bundleNumber: string): Observable<any> {
    const bundleEnv = this.appConfig.config.environmentName;
    const offering: Bundle = productbundle[bundleEnv].find((bundle: Bundle) => bundle.bundleNo === bundleNumber);

    const jobNumbers: string[] = [];

    this.lastSubmittedApplicant.firstName = accounts[0].firstName;
    this.lastSubmittedApplicant.email = accounts[0].emails[0].address;

    if (offering) {
      jobNumbers.push(offering.jobNo);
    }

    accounts.find(a => a.isCareRecipient).jobNumbers = jobNumbers;
    return of(true);
    //return this.platformApiService.createAccount$(accounts);
  }

  clearForms() {
    this.traceIdService.generateNewId();

    this.viewAsModal$.next(false);

    this.formMemberVerification$.next(new FormGroup({}));
    this.formMemberInfo$.next(new FormGroup({}));
    this.formProductInfo$.next(new FormGroup({}));
    this.formCaregiverInfo$.next(new FormGroup({}));

    this.productInfo$.next(null);
    this.tokenInformation$.next(new VaisTokenInformation());

    this.formPayment = new FormGroup({});

    if (this.productDetails) {
      this.productDetails.reset(productDetailsEmptyFormValues);
    }

    if (this.memberInfoDetails) {
      this.memberInfoDetails.reset(careRecipientEmptyFormValues);
    }

    if (this.caregiverDetails) {
      this.caregiverDetails.reset(caregiverEmptyFormValues);
    }

    this.useDefaultLogo$.next(true);
  }

  sendConfirmationEmail(recipientEmailAddress: string, emailText: string = 'congratulations') {
    return this.sendEmail(this.appConfig.config.enrollmentApiMessageEnroll, recipientEmailAddress, emailText, ' ');
  }

  sendConversionEmail(recipientEmailAddress: string, emailText: string, ammount: string) {
    return this.sendEmail(this.appConfig.config.enrollmentApiMessageConvert, recipientEmailAddress, emailText, ammount);
  }

  validateCaregiverForm() {
    this.validateCaregiverFormSource$.next(true);
  }

  updateCaregiverFormValid(isCaregiverFormValid: boolean) {
    this.isCaregiverFormValidSource$.next(isCaregiverFormValid);
  }


  submitPayment$(paymentInformation: PaymentInformation): Observable<any> {
    const repUserJson = localStorage.getItem(this.appConfig.config.localStorageRepUser);
    let repUserFormatted = 'Subscriber (self)';

    if (this.isLoggedIn && repUserJson) {
      const repUser = JSON.parse(repUserJson);
      repUserFormatted = `${repUser.userName} (${repUser.firstName} ${repUser.lastName})`;
    }

    const platformSubscriberPaymentRequest: PlatformSubscriberPaymentRequest = {
      affiliationGroup: 'UHC_FFS',
      lineItems: paymentInformation.paymentLineItems.map((paymentLineItems: PaymentLineItem) => ({
        billCode: paymentLineItems.billCode,
        description: paymentLineItems.description,
        amount: paymentLineItems.amount
      })),
      convertedBy: repUserFormatted,
      siteNumber: 0,
      firstName: paymentInformation.firstName,
      lastName: paymentInformation.lastName,
      address1: paymentInformation.streetAddress,
      address2: paymentInformation.address2,
      city: paymentInformation.city,
      state: paymentInformation.state,
      zipcode: paymentInformation.zipcode,
      creditCard: {
        accountNumber: paymentInformation.accountNumber,
        routingNumber: paymentInformation.routingNumber,
        expirationDate: paymentInformation.endYear ?
          // Intentionally "adds" a month (by not accounting for the month being zero-based in Date.UTC)
          // so expirationDate is midnight the first day of the following month
          new Date(Date.UTC(Number(paymentInformation.endYear), Number(paymentInformation.endMonth))) : new Date(),
        endDate: paymentInformation.endYear ? paymentInformation.endYear + '-' + paymentInformation.endMonth : null,
        holderName: paymentInformation.holderName ? paymentInformation.holderName :
          paymentInformation.firstName + ' ' + paymentInformation.lastName,
        cardType: paymentInformation.cardType
      },
      phoneNumber: paymentInformation.phoneNumber
    };

    const externalLookupOptions = this.enrollmentApiOptions();

    if (this.appConfig.config.fakePlatformCalls) {
      return this.simulatePaymentHttpCall(paymentInformation.firstName);
    }

    return this.httpClient.post(
      this.appConfig.config.enrollmentApiPayment,
      platformSubscriberPaymentRequest,
      externalLookupOptions);
  }

  refusePayment$(): Observable<PlatformPaymentRefusalResponse> {
    /* const platformPaymentRefusalRequest: PlatformPaymentRefusalRequest = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      SITE_REFUSAL: moment().tz('America/New_York').format('YYYY-MM-DD HH:mm:ss.SSS')
    }; */

    // const externalLookupOptions = this.enrollmentApiOptions();

    if (this.appConfig.config.fakePlatformCalls) {
      return this.simulatePaymentRefusalHttpCall();
    }

    return of({ status: 'OK' });
  }

  private sendEmail(endpoint: string, recipientEmailAddress: string, emailType: string, sender: string) {
    const sendEmailBody = {
      bodyType: emailType,
      sender, // As of this writing the API requires the field but ignores the value.
      receiver: recipientEmailAddress
    };
    return this.httpClient.post(endpoint, sendEmailBody);
  }

  private enrollmentApiOptions() {
    return {
      headers: new HttpHeaders()
        .set('AppId', this.appConfig.config.enrollmentAppId)
        .set('AppSecret', this.appConfig.config.enrollmentAppSecret)
        .set('Content-Type', 'application/json')
    };
  }

  private simulatePaymentHttpCall(firstName: string) {
    if (firstName === 'FAIL') {
      return throwError(new HttpErrorResponse({ status: 500 }));
    }

    return of(null);
  }

  private simulatePaymentRefusalHttpCall() {
    return of(null);
  }
}
