import { Injectable, OnDestroy } from '@angular/core';
import { AppConfig } from './app-config.service';
import { EMPTY, fromEvent, merge, Subject, timer } from 'rxjs';
import { startWith, switchMap, takeUntil } from 'rxjs/operators';
import { AuthService } from './auth.service';

const DEFAULT_TIMEOUT_DURATION  = 900000;

@Injectable({
  providedIn: 'root'
})
export class TimeoutService implements OnDestroy {
  timeoutTriggered$ = new Subject<void>();
  freshLoginNecessary$ = new Subject<void>();
  isLoggedIn = false;

  private timeoutDuration = DEFAULT_TIMEOUT_DURATION;
  private stopCheckingUserEvents$ = new Subject<void>();
  private stopTimer$ = new Subject<void>();

  constructor(private appConfig: AppConfig, private authService: AuthService) {
    this.timeoutDuration = this.appConfig.config.inactivityTimeoutInMinutes * 60000;

    this.authService.isLoggedIn$().subscribe(isLoggedIn => {
      this.isLoggedIn = isLoggedIn;

      if (this.isLoggedIn) {
        this.startMonitoring();
      } else {
        this.stopTimer$.next();
      }
    });
  }

  ngOnDestroy(): void {
    this.stopCheckingUserEvents$.next();
    this.stopCheckingUserEvents$.complete();
    this.stopTimer$.next();
    this.stopTimer$.complete();
  }

  private startMonitoring() {
    const userEvents$ = merge(
      fromEvent(window, 'mousedown'),
      fromEvent(window, 'keydown'),
      fromEvent(window, 'scroll'),
      fromEvent(window, 'touchstart')
    );

    userEvents$.pipe(
      startWith(null),
      switchMap(() => {
        this.stopTimer$.next();

        if (!this.isLoggedIn) {
          return EMPTY;
        }

        if (this.hasUserLoggedOutInAnotherWindow()) {
          this.stopCheckingUserEvents$.next();
          this.freshLoginNecessary$.next();
          return EMPTY;
        }

        localStorage.setItem(this.appConfig.config.localStorageLastActivity, Date.now().toString());
        return timer(this.timeoutDuration).pipe(takeUntil(this.stopTimer$));
      }),
      takeUntil(this.stopCheckingUserEvents$)
    ).subscribe(() => {
      this.timeoutTriggered$.next();
    });
  }

  private hasUserLoggedOutInAnotherWindow(): boolean {
    const localStorageRepUser = localStorage.getItem(this.appConfig.config.localStorageRepUser);
    return !localStorageRepUser;
  }
}
